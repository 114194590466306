import './MiniSlide.css';
import React from 'react';
import { Link } from 'react-router-dom';

class MiniSlide extends React.Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <div
        className="product-item"
        style={{
          backgroundColor: '#e7f0f9c7',
          backgroundImage: `url(${this.props.data.image})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '425px',
          backgroundBlendMode: 'multiply',
          fontSize: '20px',
          fontWeight: '600',
          color: '#fff',
          padding: '10px',
          textShadow: '3px 1px 4px #000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          
        }}
      >
        <Link to={this.props.data.link} style={{textDecoration:'none', color: '#fff'}}>
          {this.props.data.title}
        </Link>
      </div>
    );
  }
}

export default MiniSlide;