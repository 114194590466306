import React, { useState, useRef, useEffect, useCallback } from 'react'
import ProductCard from './ProductCard'
import styles from './Section.module.css'
import ProductItem from '../product-item/ProductItem'
import request from '../request';

function Section(props) {
  const [products, setProducts] = useState(props.section.products || [])
  const [minHeight, setMinHeight] = useState(325)
  const [background, setBackground] = useState(props.section.background || null)
  const [isShown, setIsShown] = useState(false)
  const [editedName, setEditedName] = useState(props.section.name)  
  const [editedHeight, setEditedHeight] = useState(props.section.height)  
  const [color, setColor] = useState(props.section.color || null)
  const [backgroundColor, setBackgroundColor] = useState(props.section.backgroundColor || null)
  const [updatedProducts, setUpdatedProducts] = useState([]);

  useEffect(() => {
    props.onUpdateProducts({ ...props.section, products, background, color, backgroundColor, name: editedName, height: editedHeight})
  }, [products, background, color, backgroundColor, editedName, editedHeight])

  const handleImageLoad = (e) => {
    e.preventDefault()
    setMinHeight(e.target.height)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newUpdatedProducts = await Promise.all(
          products.map(async (product) => {
            const response = await request.get(`api/product/${product.id}`);
            if (response.status === 200) {
              console.log(response.data)
              const { thumbnail, title, title_en, ...otherProperties } = response.data;
              return { image: thumbnail, name: title, name_en: title_en, ...otherProperties };
            }
            return product;
          })
        );
        setUpdatedProducts(newUpdatedProducts);
      } catch (error) {
        console.log(error);
      }
    };
    

    if (products.length > 0) {
      fetchData();
    }

}, [products]);

  return (
    <>
    <div
      className={styles['section']}
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: `${editedHeight ? editedHeight : minHeight}px`,
        border: color ? `3px solid rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : '3px solid white',
        backgroundColor: backgroundColor ? `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})` : '3px solid white'
      }}
    >
      <img src={background} alt={props.section.name} onLoad={handleImageLoad} style={{ display: 'none' }} />

      {props.section.name ? (<h3 className={styles["section-title"]}>{props.section.name}</h3>) : null}

      <div className={styles['products']}>
      {updatedProducts.length > 0 && updatedProducts?.map((product, index) => {
        return (
          <div key={index} className="product-wrapper">
            <ProductItem key={index} data={product} />
            {/*<ProductCard product={product} onDelete={(e) => handleProductDelete(index, e)} />*/}
          </div>
        );
      })}
      </div>

    </div>
    </> 
  )
}

export default Section
