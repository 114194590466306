import React, { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Link,
} from "react-router-dom";
import './Header.css';
import i18n from '../i18n';
import LanguageSelect from '../language-select/LanguageSelect';
import SearchWindow from '../search-window/SearchWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faSearch, faUserCircle, faBars, faShoppingBasket, faTimes, faSignInAlt, faSignOutAlt, faJedi, faCaretRight, faCaretLeft, faSpinner, faDotCircle as faDotCircleSolid, faGift, faAlignCenter } from '@fortawesome/free-solid-svg-icons'
import { faClock, faHeart, faUserCircle as faUserCircleO, faDotCircle, } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import storage from '../storage';
import logoImage from '../assets/img/logo.png';
import Discounts from '../discounts/Discounts';
import discountBasket from '../discountBasket';
import ReactTooltip from "react-tooltip";
import config from '../config';
import SearchHeader from '../search-window/SearchHeader';
import basket from '../basket';

function Header(props) {
  const userMenuRef = useRef(null);
  const [showBasketToast, setShowBasketToast] = useState(false);
  const [searchWindowOpen, setSearchWindowOpen] = useState(false);
  const [navMobileOpen, setNavMobileOpen] = useState(false);
  const [discountsWindowOpen, setDiscountsWindowOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [userMobileMenuOpen, setUserMobileMenuOpen] = useState(false);
  const [navigationMenuType, setNavigationMenuType] = useState(localStorage['navigation-menu-type'] || 'normal');
  const [categories, setCategories] = useState([]);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(localStorage['header-fixed'] || '0');
  const [categoriesTree, setCategoriesTree] = useState([])
  const [categoryTreeView, setCategoryTreeView] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const elementRef = React.createRef();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };


  useEffect(() => {
    const handleClickOutside = () => {
      setUserMenuOpen(false)
      //setNavMobileOpen
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const handleClickOutsideMenu = () => {
      setCategoryTreeView(false)
    };

    document.addEventListener('click', handleClickOutsideMenu, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu, true);
    };
  });

  useEffect(() => {
    const handleClickOutsideBrands = () => {
      setDropdownVisible(false)
    };

    document.addEventListener('click', handleClickOutsideBrands, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideBrands, true);
    };
  });  

  useEffect(() => {
    if (categoryTreeView) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }

    return () => {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [categoryTreeView])

  useEffect(() => {

    const handleCloseCategories = (e) => {
      if (e.target == document.querySelector('.categories-tree'))
        setCategoryTreeView(false)
    };
    let a = document.querySelector('.categories-tree');
    if (a) {
      a.addEventListener('click', handleCloseCategories, true);
      return () => {
        a.removeEventListener('click', handleCloseCategories, true);
      };
    }

  });

  useEffect(() => {
    const discount_evt = discountBasket.onQtyChange(() => {
      setShowBasketToast(true)
      setTimeout(() => {
        setShowBasketToast(false)
      }, 20000)
    })

    return () => {
      discount_evt()
    }
  })

  useEffect(() => {
    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });



    let storage_categories_tree;
    setFetchingCategories(true);
    request.get(`api/categories`).then((response) => {
      let data = response.data;

      setCategories(data);

      setFetchingCategories(false);


      storage_categories_tree = storage.onChange(() => {
        console.log(storage);
        if (storage.categories_tree && storage.categories_tree[0] && storage.categories_tree.length > 0) {
          storage.categories_tree[0].active = true;
        }
        setCategoriesTree(storage.categories_tree);
      })

      storage.set('categories', data);
      //setCategoriesTree(storage.categories_tree);

    });

    return () => {
      unlistenOnLanguageChange();
      storage.clearOnChange(storage_categories_tree)
      //window.removeEventListener('click', onWindowClick);
    };
  }, []);



  const location = useLocation();
  useEffect(() => {
    setNavMobileOpen(false);
  }, [location]);

  useEffect(() => {
    if (navMobileOpen) {
      const mobileNavBtnClosePos = elementRef.current.querySelector('.navbar-mobile .btn-close').getBoundingClientRect();
      elementRef.current.querySelector('.navbar-mobile .content').style.top = mobileNavBtnClosePos.top + mobileNavBtnClosePos.height + (window.innerHeight * 0.09) + 'px';
    }
  }, [navMobileOpen]);

  useEffect(() => {
    const storage_fn_key = storage.onChange(() => {
      if (storage.me && storage.me.company_name) {
        setUserEmail(storage.me ? storage.me.company_name : null)
      } else {
        setUserEmail(storage.me ? storage.me.email : null)
      }

    })
    if (storage.me && storage.me.company_name) {
      setUserEmail(storage.me ? storage.me.company_name : null)
    } else {
      setUserEmail(storage.me ? storage.me.email : null)
    }
    return () => {
      storage.clearOnChange(storage_fn_key)
    }

  }, [])

  function closeSearchWindow() {
    setSearchWindowOpen(false);
  }

  function openMobileNavMenu() {
    setNavMobileOpen(true);
  }

  function closeMobileNavMenu() {
    setNavMobileOpen(false);
  }

  function changeNavigationMenuType(type) {
    setNavigationMenuType(type);
    localStorage.setItem('navigation-menu-type', type);
  }

  function changeHeaderFixed(state) {
    setHeaderFixed(state);
    localStorage.setItem('header-fixed', state);
    props.onHeaderTypeChange(state === '1' ? 'sticky' : 'normal');
  }

  function logout() {
    request.get('api/logout').then((response) => {
      if (response.data.status === 'ok') {
        props.onLogout();
      }
    });
  }

  function setInitialActiveCategory() {
    const cat = JSON.parse(JSON.stringify(categoriesTree));
    cat[0].active = true;
    setCategoriesTree(cat)
  }

  function setActiveCategory(c) {
    const cat = JSON.parse(JSON.stringify(categoriesTree));
    cat.map(c1 => {
      if (c1.id == c.id) {
        c1.active = true;
      }
      else {
        c1.active = false;
      }

      return c1;
    });
    setCategoriesTree(cat)
  }

  let isLoggedIn = props.user.authenticated && Object.keys(storage.me).length > 0;

  const signinOptions = () => {
    if (isLoggedIn) {
      return (<React.Fragment>
        <Link to="/account">
          <FontAwesomeIcon icon={faUserCircleO} />
          &nbsp;
          {i18n.t('my_account')}
        </Link>
        <Link to="" onClick={logout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          &nbsp;
          {i18n.t('signout')}
        </Link>
      </React.Fragment>);
    } else {
      return (
        <React.Fragment>
          <Link to="/signin">
            <FontAwesomeIcon icon={faSignInAlt} />
            &nbsp;
            {i18n.t('signin')}
          </Link>
          <Link to="/signup">
            <FontAwesomeIcon icon={faJedi} />
            &nbsp;
            {i18n.t('signup')}
          </Link>
        </React.Fragment>);

    }
  }

  return (
    <div
      className={`header ${headerFixed === '1' ? 'fixed' : ''}`}
      ref={elementRef}
    >
      <div className="topbar container">
        <div className="logo">
          <Link to="/">
            <img src={logoImage} height="70" alt="Deal Markets Logo" />
          </Link>
        </div>

        <div className="top-left">
          <a className="logo-phone" href='tel:+302105224576'>210 522 4576</a>
          <div className="social">
                      {
                        storage.company.social_facebook !== '' ?
                        <a className="social-links" href={storage.company.social_facebook}><FontAwesomeIcon icon={faFacebook} /></a> :
                        ''
                      }
                      {
                        storage.company.social_instagram !== '' ?
                        <a className="social-links" href={storage.company.social_instagram}><FontAwesomeIcon icon={faInstagram} /></a> :
                        ''
                      }
                      {
                        storage.company.social_pinterest !== '' ?
                        <a className="social-links" href={storage.company.social_pinterest}><FontAwesomeIcon icon={faPinterest} /></a> :
                        ''
                      }
          </div>
        </div>                  

        <div className="options">
          <div className="searchtopbar">
            <SearchHeader></SearchHeader>
          </div>
          <div className="user"
            onClick={(e) => {
              e.stopPropagation()
              setUserMenuOpen(!userMenuOpen)
            }}>
            <div style={{ display: "inline-block" }} ref={userMenuRef}>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="lg"
                style={{ color: "#b5b5b5" }}

              />
            </div>
            <span style={{ marginLeft: "4px", fontSize: "12px" }}>{isLoggedIn ? userEmail : i18n.t('signin')}</span>
            <div
              className={`options ${userMenuOpen ? 'open' : ''}`}
            >
              {signinOptions()}
            </div>
          </div>
          <LanguageSelect></LanguageSelect>
          <div className="basket desktop-btn" onClick={props.onOpenBasket}>
          <div className="total-qty"><span>{basket.products.map(p => p.quantity).reduce((a, b) => a + Number(b), 0)}</span></div>
            <FontAwesomeIcon
              icon={faShoppingBasket}
              size="lg"
              data-tip data-for={`controlsTip-basket`}
            />
            <ReactTooltip id={`controlsTip-basket`} place="bottom" effect="solid" type="info">
              {i18n.t("basket")}
            </ReactTooltip>
            <span className="total-cart">{(basket.products.map(p => p.prices[0].price * p.quantity).reduce((a, b) => a + b, 0)).toFixed(2)}€</span>
            {console.log(basket.products)}
            {console.log("refreshed")}
          </div>

        </div>
      </div>
      <div className="navbar container">
        <div className='navigation'>
          <Link to="/">{i18n.t('home_page')}</Link>
          <span onClick={() => setCategoryTreeView(!categoryTreeView)}>{i18n.t('categories')}</span>
          {
            categoriesTree && categoriesTree.length > 1 ?
              <Link to="/categories">{i18n.t('categories')}</Link>
              : categoriesTree && categoriesTree.length > 0 &&
              <Link to={`/categories/${categoriesTree[0].id}`}>{i18n.t('products_navigation')}</Link>
          }

          {/* <Link to="/basket">{i18n.t('basket')}</Link> */}
          <div className={`categories-tree ${categoryTreeView ? 'show' : ''}`}>
          {/* <MegaMenu categories={categoriesTree} /> */}
            {
              categoriesTree && categoriesTree.length > 0 &&
              <div className="child-category">
                {
                  categoriesTree.map((root, i) => {
                    return <div key={`subcategories-${i}`} className={`sub-categories ${root.active ? 'active' : ''}`} >
                      <ul className="categories-list">
                        {root.children &&
                          root.children.length > 0 &&
                          root.children.map((main, i) => {
                            if (main.children.length > 0) {
                              return (
                                <React.Fragment key={`main-${i}`}>
                                  <li className="third-level">
                                    <div className="category-img">
                                      {main.image != "" && (
                                        <img
                                          src={
                                            main.mini_image && main.mini_image != ""
                                              ? main.mini_image
                                              : main.image
                                          }
                                          alt={i18n.get_name(main)}
                                        />
                                      )}
                                      <Link
                                        to={`/categories/${main.id}`}
                                        onClick={() => setCategoryTreeView(false)}
                                      >
                                        {i18n.get_name(main)}
                                      </Link>
                                    </div>
                                  </li>
                                  <div className="wrapper-li">
                                    <div className="child-wrap">
                                      {main.children.map((child, j) => (
                                        <div className="columns" key={`child-${j}`}>
                                          <div className="title-child text-black fs-6 pb-2">
                                            <Link to={`/categories/${child.id}`}>{i18n.get_name(child)}</Link>
                                          </div>
                                          <div className="sub-list">
                                            {child.children.map((sub, j) => (
                                              <Link
                                                to={`/categories/${sub.id}`}
                                                style={{ fontWeight: 400 }}
                                                onClick={() => setCategoryTreeView(false)}
                                              >
                                                {i18n.get_name(sub)}
                                              </Link>
                                            )
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <React.Fragment key={`main-${i}`}>
                                  <li className="third-level">
                                    <div className="category-img">
                                      {main.image != "" && (
                                        <img
                                          src={
                                            main.mini_image && main.mini_image !== ""
                                              ? main.mini_image
                                              : main.image
                                          }
                                          alt={i18n.get_name(main)}
                                        />
                                      )}
                                      <Link
                                        to={`/categories/${main.id}`}
                                        style={{ fontWeight: 600 }}
                                        onClick={() => setCategoryTreeView(false)}
                                      >
                                        {i18n.get_name(main)}
                                      </Link>
                                    </div>
                                  </li>
                                </React.Fragment>
                              );
                            }
                          })}
                      </ul>

                      {/* <div className='category-img'>
                              {
                                c.image != '' &&
                                <img src={c.image} />
                              }
                            </div> */}
                    </div>

                  })
                }
              </div>
            }


          </div>

            <span to="#" onClick={toggleDropdown} className="dropdown">
              {i18n.t('brands')}
            </span>
              <ul className={`dropdown-list ${dropdownVisible ? '' : 'hidden'}`}>
                <li><Link to="/categories/0/1/22">COLGATE</Link></li>
                <li><Link to="/categories/0/1/9">FAIRY</Link></li>
                <li><Link to="/categories/0/1/16">DOVE</Link></li>
                <li><Link to="/categories/0/1/24">CHUPA CHUPS</Link></li>
                <li><Link to="/categories/0/1/13">ELEF</Link></li>
                <li><Link to="/categories/0/1/18">DETTOL</Link></li>                
              </ul>

          <a target='_blank' href="https://manager.kremmydas.gr/files/filladio.pdf">{i18n.t('brochure')}</a>
        </div>
        <div className="controls">
          <div className="search" onClick={() => setSearchWindowOpen(true)}>
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              data-tip data-for={`controlsTip-search`}
            />
            <ReactTooltip id={`controlsTip-search`} place="bottom" effect="solid" type="info">
              {i18n.t("search")}
            </ReactTooltip>
          </div>
          {/*
          <div className="discounts" onClick={() => setDiscountsWindowOpen(true)}>
            <FontAwesomeIcon
              icon={faGift}
              size="lg"
              data-tip data-for={`controlsTip-discounts`}
            />
            <ReactTooltip id={`controlsTip-discounts`} place="bottom" effect="solid" type="info">
              {i18n.t("discounts_and_gifts")}
            </ReactTooltip>
          </div>
          */}
          {/* <div className="user">
            <FontAwesomeIcon
              icon={faUserCircle}
              size="lg"
              ref={userMenuRef}
              onClick={(e) => {
                e.stopPropagation()
                setUserMenuOpen(!userMenuOpen)
              }}
            />
            <div 
              className={`options ${userMenuOpen ? 'open' : ''}`}
            >
              {signinOptions()}
            </div>
          </div> */}
          <div className="favourite">
            <Link to="/products">
              <FontAwesomeIcon
                icon={faHeart}
                size="lg"
                data-tip data-for={`controlsTip-favs`}
              />
              <ReactTooltip id={`controlsTip-favs`} place="bottom" effect="solid" type="info">
                {i18n.t("favorites")}
              </ReactTooltip>
            </Link>
          </div>
          {/* <div className="basket"  onClick={props.onOpenBasket}>
            <FontAwesomeIcon
              icon={faShoppingBasket}
              size="lg"
              data-tip data-for={`controlsTip-basket`}       
            />
            <ReactTooltip id={`controlsTip-basket`} place="bottom" effect="solid" type="info">
              {i18n.t("basket")}
            </ReactTooltip>
          </div> */}
        </div>
        <div className="mobile-controls">
          <div className="btn-mobile-navigation" onClick={openMobileNavMenu}>
            <FontAwesomeIcon
              icon={faBars}
            />
          </div>
        </div>
      </div>

      <div className={`navbar-mobile ${!navMobileOpen ? 'hidden' : ''}`} onClick={closeMobileNavMenu}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
        />
        <div className="content">
          <div className="controls">
            <div className="search" onClick={() => setSearchWindowOpen(true)}>
              <FontAwesomeIcon
                icon={faSearch}
                size="2x"
              />
            </div>
            <div className="discounts" onClick={() => setDiscountsWindowOpen(true)}>
              <FontAwesomeIcon
                icon={faGift}
                size="2x"
              />
            </div>
            <div className="user">
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                style={{ color: "#b5b5b5" }}
              />
              <div
                className={`options ${userMobileMenuOpen ? 'open' : ''}`}
              >
                {
                  props.user.authenticated === false
                    ? <Link to="/signin">
                      <FontAwesomeIcon icon={faSignInAlt} />
                      &nbsp;
                      {i18n.t('signin')}
                    </Link>
                    : ''
                }
                {
                  props.user.authenticated === false
                    ? <Link to="/signup">
                      <FontAwesomeIcon icon={faJedi} />
                      &nbsp;
                      {i18n.t('signup')}
                    </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                      <FontAwesomeIcon icon={faUserCircleO} />
                      &nbsp;
                      {i18n.t('my_account')}
                    </Link>
                    : ''
                }
                {
                  props.user.authenticated
                    ? <Link to="">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      &nbsp;
                      {i18n.t('signout')}
                    </Link>
                    : ''
                }
              </div>
            </div>
            <div className="favorites">
              <Link to="/products">
                <FontAwesomeIcon
                  icon={faHeart}
                  size="2x"
                />
                <span className="d-none">Favourite Products</span>
              </Link>
            </div>
            <div className="basket" onClick={props.onOpenBasket}>
              <FontAwesomeIcon
                icon={faShoppingBasket}
                size="2x"
              />
            </div>
          </div>
          <div className="navigation">
            <Link to="/">{i18n.t('home_page')}</Link>
            <Link to="/categories">{i18n.t('products_navigation')}</Link>
            {
              categoriesTree && categoriesTree.length > 1 ?
                <Link to="/categories">{i18n.t('categories')}</Link>
                : categoriesTree && categoriesTree.length > 0 &&
                <Link to={`/categories/${categoriesTree[0].id}`}>{i18n.t('categories')}</Link>
            }
            <Link to="/contact">{i18n.t('contact')}</Link>
            <Link to="/basket">{i18n.t('basket')}</Link>
          </div>
        </div>
      </div>
      <SearchWindow
        open={searchWindowOpen}
        onClose={closeSearchWindow}
      />
      <Discounts
        open={discountsWindowOpen}
        onClose={setDiscountsWindowOpen}
      />
      <div className={`toast toast-review-basket`} style={{ display: showBasketToast ? 'block' : 'none' }}>
        <div onClick={() => setShowBasketToast(false)}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
          />
        </div>
        {i18n.t('removed_discount_prompt')} <button className='btn btn-primary' onClick={() => {
          props.onCloseBasket()
          setShowBasketToast(false)
          setDiscountsWindowOpen(true)
        }}>{i18n.t("here")}</button>
      </div>
    </div>
  );
}

export default Header;
