import './ProductPage.css';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useParams, Redirect} from 'react-router-dom';
import request from '../request';
import config from '../config';
import NumberPicker from '../number-picker/NumberPicker';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingBasket, faTimes, faPaperPlane, faBasketballBall, faRedo} from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import favouritesHelper from '../favouritesHelper';
import history from '../history';
import ProductAvailaibility from '../product-availability/ProductAvailability';
import basket from '../basket';
import ProductColors from '../product-colors/ProductColors';
import Product360 from '../product-360/Product360';
import ProductPrices from '../product-prices/ProductPrices';
import ProductBasket from '../product-basket/ProductBasket';
import ProductDiscount from '../product-discount/ProductDiscount';
import gtag_evt from '../gtag';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CategoryItem from '../category-item/CategoryItem';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import ProductItem from '../product-item/ProductItem';
import storage from '../storage';

function ProductPage(props) {
  const params = useParams();
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderNavRef, setSliderNavRef] = useState(null);
  const [gtag_init, setGtagInit] = useState(false)
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [product, setProduct] = useState({
    availability: {},
  });
  const [sameBrandProducts, setSameBrandProducts] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(false);
  const [latestOrders, setLatestOrders] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [count, setCount] = useState(1);
  const [inBasket, setInBasket] = useState(false);

  const [imageView, setImageView] = useState("colors");
  const [play360, setPlay360] = useState(false);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [relationTypes, setRelationTypes] = useState([]);
  const [relations, setRelations] = useState([]);

  const fetchRelations = () => {
    
    request.get(`api/relation-types`, false ).then((response) => {
      if(response.data){
        setRelationTypes(response.data);
      }
    });

    if(relationTypes.length == 0) return;

    request.get(`api/product/relations/${params.productId}`, false ).then((response) => {
      if(response.data){
        setRelations(response.data);
      }
    });
  }

  const getPrice = (pid, cid) => {
    request.get(`api/product/price/${pid}/${cid}/1`, false ).then((response) => {
      if(response.data){
        console.log(response.data);
      }
    });
  }
  
  const fetchProduct = () => {
    // setFetchingProduct(true);
    // if(typeof product.id != 'undefined'){
    //   setFetchingProduct(false);
    //   return;
    // }
    // let resolveFn;
    // const promise = new Promise((resolve, reject) => resolveFn = resolve);
    let prods = JSON.parse(localStorage.getItem("basket"));

   
    //if(fetchingProduct) return;
    request.post(`api/product/${params.productId}`, false ).then((response) => {
      // console.log("response is", response)
      if(!response.data.id){
        history.push("/categories");
      }
      const productUpdated = {
        id: response.data.id,
        name: response.data.title,
        name_en: response.data.title_en,
        thumbnail: response.data.thumbnail,
        images: response.data.images,
        code: response.data.code,
        miniDescription: response.data.mini_description,
        miniDescription_en: response.data.mini_description_en,
        description: response.data.description,
        description_en: response.data.description_en,
        image: response.data.thumbnail,
        rating: response.data.rating,
        price: response.data.initial_price,
        discountPrice: response.data.price,
        availability: response.data.availability,
        variants: response.data.variants,
        colors: response.data.colors,
        i360: response.data.i360,
        prices: response.data.prices,
        current_price: null,
        picking_package: response.data.picking_package,
        picking_price: response.data.picking_price,
        picking_limit: response.data.picking_limit,
        passive_discounts: response.data.passive_discounts,
        active_discounts: response.data.active_discounts,
        marketing_message: response.data.marketing_message,
        manufacturer: response.data.manufacturer,
        discount: response.data.discount ? response.data.discount : null,
        campain: response.data.campain ? response.data.campain : null,
        quantity: response.data.quantity,
        package_qty: response.data.package_qty,
        vat_percent: response.data.vat_percent,
        selected_quantity : response.data.package_qty,
        current_pckg : 1,
        category_id : response.data.category.id,
        category_name: response.data.category.name ? response.data.category.name : null,
        qty_step : response.data.qty_step ? response.data.qty_step : 0,
        mu_code: response.data.mu_code ? response.data.mu_code : 0,
      };

      if (!gtag_init) {
        const item_price = productUpdated.prices && productUpdated.prices.length > 0 ? productUpdated.prices[0].price : 0;
        gtag_evt.gtag('event', 'view_item', {
          currency: 'EUR',
          items: [{
            item_id: productUpdated.code,
            item_name: productUpdated.name,
            item_brand: productUpdated.manufacturer,
            item_category: null,
            price: item_price,
            currency: 'EUR'
          }],
          value: item_price
        })

        setGtagInit(true)
      }
      // passive_discounts: response.data.data.passive,
      // active_discounts: response.data.data.active,
      setProduct(productUpdated);
      setFetchingProduct(false);
      setInBasket(basket.products.findIndex((i) => i.id === productUpdated.id) > -1)
      
      document.title = `${i18n.get_name(productUpdated)} - ${config.eshop_title}`;
      let lastSeenProducts = localStorage.getItem('last-seen-products');
      if (lastSeenProducts) {
        lastSeenProducts = JSON.parse(lastSeenProducts);
      } else {
        lastSeenProducts = [];
      }

      if (lastSeenProducts.indexOf(params.productId) === -1) {
        lastSeenProducts.push(params.productId);
        if (lastSeenProducts.length > 8) {
          lastSeenProducts.shift();
        } 
      }
      //getDiscounts(); 
      localStorage.setItem('last-seen-products', JSON.stringify(lastSeenProducts));

      //resolveFn(productUpdated);
      // setTimeout(() => {
      
    })
    // });

   

    //return promise;
  }

    
  useEffect(() => {
    if (!product.id) return;

    request.get(`api/products/related/${product.category_id}`).then((response) => {
      if(!request.response_valid(response)) {
        return;
      }
      //console.log(response.data.data);
      // return;
      setRelatedProducts(response.data.data.products.map((product_related, i) => {
        return {
          id: product_related.id,
          code: product_related.code,
          name: product_related.title,
          name_en: product_related.title_en,
          description: product_related.description,
          image: product_related.thumbnail || product_related.images[0],
          rating: product_related.rating,
          price: product_related.price,
          prices: product_related.prices,
          availability: product_related.availability,
          variants: product_related.variants,
          colors: product_related.colors,
          manufacturer: product_related.manufacturer,
          package_qty: product_related.package_qty,
          qty: product_related.package_qty,
          vat_percent: product.vat_percent,
          max_qty:  product_related.max_quantity,
          discount: product_related.discount ? product_related.discount : null
        }
      }))
      
      
    })
  
    if(storage.me.id){
    request.get(`api/products/last_orders/${storage.me.id}/${product.id}`).then((response) => {
      if(!request.response_valid(response)) {
        return;
      }
      setLatestOrders(response.data?.data?.products.map((latest_orders, i) => {
        return{
          date: latest_orders.date,
          quantity: latest_orders.qty,
        }
      }))
    })
    }

    request.get(`api/products/samebrand/${product.manufacturer}`).then((response) => {
      
      if(!request.response_valid(response)) {
        return;
      }
      //console.log(response.data.data);
      setSameBrandProducts(response.data.data.products.map((product_related, i) => {
        return {
          id: product_related.id,
          code: product_related.code,
          name: product_related.title,
          name_en: product_related.title_en,
          description: product_related.description,
          image: product_related.thumbnail || product_related.images[0],
          rating: product_related.rating,
          price: product_related.price,
          prices: product_related.prices,
          availability: product_related.availability,
          variants: product_related.variants,
          colors: product_related.colors,
          manufacturer: product_related.manufacturer,
          package_qty: product_related.package_qty,
          vat_percent: product.vat_percent,
          quantity: product_related.quantity,
          discount: product_related.discount ? product_related.discount : null
        }
      }))
    })
  }, [product])

  // function getDiscounts() {
  //   let prods = JSON.parse(localStorage.getItem("basket"));
  //   //console.log(prods)

  //   request.post(`api/discounts/${params.productId}`, {
  //     basket: prods
  //   }).then( (response) => {
  //     if(response.data){
  //       setProduct({
  //         ...product,
  //         passive_discounts: response.data.data.passive,
  //         active_discounts: response.data.data.active,
  //       })
  //       console.log("product is", product)
  //     }
      
  //   })

  // }

  useEffect(() => {
    //setFetchingProduct(true);
    
    //fetchProduct(); 

    
    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });
    

    return () => {
      unlistenOnLanguageChange();
    };
  }, []);

  useEffect(() => {
    if(imageView === '360'){
      setPlay360(true);
    }else{
      setPlay360(false);
    }
  }, [imageView])

  useEffect(() => {
    setFetchingProduct(true);
    fetchProduct(); 
    fetchRelations();
    
  }, [params]);

  function onChangeImage(p) {
    if(p.url){
      setProduct({
        ...product,
        thumbnail: p.url,
      });
    }
  }

  function closeToast() {
    setShowToast(false);
  }

  function addToFavourite() {
    favouritesHelper.add(product);
  }

  const onPriceSetChange = (qty) => {
    
    if(product.prices && product.prices.length > 0){
      let current_price = null
      let valid_prices = product.prices.filter( (p) => {
        return parseInt(p.qty) <= qty
      })
      if(valid_prices.length > 0){
        current_price = valid_prices[0]
        let single_price = valid_prices.reduce(function (p, v) {
          return ( parseInt(p.qty) > parseInt(v.qty) ? p : v );
        });
        current_price = single_price;
      }
      //console.log(current_price, product.current_price, qty)
      if(!product.current_price || (current_price && current_price.price != product.current_price)){
        return current_price
      }
    }
  }

  /*
  const changeQty = ((qty)=> {
    const current_qty = parseInt(product.selected_quantity) + (qty * product.package_qty)
    const current_price = onPriceSetChange(current_qty > 0 ? current_qty : product.package_qty);
    const current_pckg = parseInt(product.current_pckg) + qty;
    setProduct({
      ...product,
      selected_quantity: current_qty > 0 ? current_qty : product.package_qty,
      current_price: current_price ? current_price.price || product.current_price : product.current_price,
      current_pckg: current_pckg > 0 ? current_pckg : 1
    })
    
  })
*/

const changeQty = ((qty)=> {

  const split = parseInt(product.selected_quantity) + qty <= product.package_qty
  const current_qty = parseInt(product.selected_quantity) + (qty * parseInt((product.qty_step > 0 && split) ? product.qty_step : product.package_qty));

  console.log(split, current_qty, product.qty_step)  

  if ( (current_qty < product.qty_step) ) return;   

  const current_price = onPriceSetChange(current_qty > 0 ? current_qty : parseInt((product.qty_step > 0) ? product.qty_step : product.package_qty));
  let current_pckg = (split) ? parseFloat(current_qty / product.package_qty).toFixed(1) : parseInt(product.current_pckg) + qty;

  current_pckg = (current_pckg % 1 === 0) ? parseInt(current_pckg) : current_pckg;

  setProduct({
    ...product,
      selected_quantity: current_qty > 0 ? current_qty : parseInt((product.qty_step > 0) ? product.qty_step :product.package_qty),
      current_price: current_price ? current_price.price || product.current_price : product.current_price,
      current_pckg: current_pckg > 0 ? current_pckg : 1
  });

})

  const addToBasket = (() => {
    basket.addProduct(product, product.selected_quantity)
    setInBasket(true)
    setShowToast(basket.products.findIndex((i) => i.id === product.id) > -1)
  })

  function onDiscountTrigger(e) {
    let act = product.active_discounts;
    if(e.show){
      act.discount.total_qty = e.total_qty;
      setProduct({
        ...product,
        active_discounts: act
      });
    }
    setShowDiscount(e.show)
  }
  
  function closeDiscount(e) {
    setShowDiscount(false)
  }

  function getBrandImage(id) {
    if(id == "1"){
      return 'K PRIME.png';
    }else if(id == "2"){
      return 'FAGEO.png';
    }else if(id == "3"){
      return 'KEYA.png';
    }else if(id == "4"){
      return 'GILDAN.png';
    }else if (id == "5"){
      return 'FOTL.png';
    }
    return false; 
  }

  const renamedAttributes = {
    title: 'name',
    title_en: 'name_en',
    thumbnail: 'image',
  };

    
  
  return (
    <div className="product-page container py-4">
      <div className='row'>
        <div className={`skeleton ${fetchingProduct === false ? 'hidden' : ''}`}>
          <div className="skeleton-image"></div>
          <div className="skeleton-details"></div>
        </div>
        <div className={`basic-info ${fetchingProduct ? 'hidden' : ''}`}>
          <div className="image">
            <Slider {...{
             slidesToShow: 1,
             slidesToScroll: 1,
             arrows: false,
             ref: (slider) => setSliderRef(slider),
             asNavFor: sliderNavRef,
             responsive: [
              {
                breakpoint: 1200,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
            }}>

              {
                product.images &&
                product.images.map((img,i) => {
                
                  // console.log(img)
                  return <div key={i}>
                   <ReactImageMagnify {
                    ...{
                      smallImage: {
                        isFluidWidth: true,
                        src:img,
                      },
                      largeImage: {
                        src: img,
                        width: 1426,
                        height: 2000
                      },
                      enlargedImageContainerStyle: {
                        zIndex: "1500"
                      },
                      enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%"
                      }
                    // lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                    }
                   }
                      // <img className='img-slider-product' src={imgs}></img>
                  />
                  </div>
                })
              }
            </Slider>
            {product.images && product.images.length > 1 && (
            <Slider {...{
             slidesToShow: 4,
             slidesToScroll: 1,
             infinite: false,
             arrows: false,
             swipeToSlide:true,
            focusOnSelect:true,
             ref: (slider) => setSliderNavRef(slider),
             asNavFor: sliderRef,
            }}>
              {
                product.images &&
                product.images.map((imgs,i) => {
                
                  // console.log(imgs)
                  return <div key={i}>
                   
                      <img  style={{width: '100px', height: 'auto', objectFit: 'contain'}} src={imgs}></img>
                  </div>
                })
              }
            </Slider>
            )}
            {/* {
              !fetchingProduct && product.i360 && product.i360.length > 0 && product.colors &&
              <div className="images-controls">
                <button onClick={ () => setImageView("360")}  className={`${imageView == "360" && "active"}`}>
                  <FontAwesomeIcon icon={faRedo}/> <br/>
                  360°
                </button>
                <button onClick={ () => setImageView("colors")} className={`color-btn ${imageView == "colors" && "active"}`}>
                </button>
              </div>
            } */}
            {/* {
              getBrandImage(product.manufacturer) && 
              <div className='brand-img'>
                <img src={`${config.imagesBaseURL}/${getBrandImage(product.manufacturer)}`} alt="brand image"/>
              </div>
            } */}
            
           
            {/* {
              !fetchingProduct && product.i360 && product.i360.length > 0 &&
              <div className={`images-view i360-image-view ${imageView != "360" && "hidden"}`}>
                <div className="mt-5">
                  <Product360 data={product.i360} play={play360}/>
                </div>
              </div>
            } */}
            
          </div>
          <div className={`details ${inBasket ? 'in-basket' : ''}`}>
            <span className="name">{i18n.get_name(product)}</span>
            <span className="sku">ΚΩΔΙΚΟΣ: {product.code}</span>
            {
              !fetchingProduct && product.prices ?
              <div className="mt-4 price py-2">
                {
                  product.prices[0].discount_percent_1 > 0 ? 
                  <div className={`discount-price discount-price-1`}>
                    <div className='discount-price-element'>{i18n.t('from')} {parseFloat(product.prices[0].initial_price).toFixed(2)}&nbsp;€ </div>
                    <div className='discount-percent-element'>({parseFloat(product.prices[0].discount_percent_1).toFixed(0)}% {i18n.t('discount')})</div>
                  </div> : ''
                }
                {
                  product.prices[0].discount_percent_2 > 0 ? 
                  <div className={`discount-price discount-price-1`}>
                    <div className='discount-price-element'>{i18n.t('from')} {parseFloat(product.prices[0].discount_price_1).toFixed(2)}&nbsp;€ </div>
                    <div className='discount-percent-element'>({parseFloat(product.prices[0].discount_percent_2).toFixed(0)}% {i18n.t('discount')})</div>
                  </div> : ''
                }
                <span className={`initial-price final-price`}>
                  <div className='final-price-text'>
                  {( product.prices[0].discount_percent_1 > 0 || product.prices[0].discount_percent_2 > 0) ? i18n.t('final_price') : i18n.t('price')}
                  </div>
                  <div className='final-price-num'>{parseFloat(product.prices[0].price).toFixed(2)}&nbsp;€</div>
                </span>
                {/* <ProductPrices data={product.prices} price={product.current_price}/> */}
              </div>
              :
              <div className="price py-2"><span className="initial-price">Συνδεθείτε για να δείτε τιμή €</span></div>
            }
            
            {/* <div className="price">
              <span className="current-price">{calculatePrice()}&nbsp;€</span>
            </div> */}
            
            {product.availability && <ProductAvailaibility data={product.availability} />}
            
            <div className={`extra-info ${fetchingProduct ? 'hidden' : ''}`}>
              {
                !fetchingProduct && product.description && product.description.length > 0 && <React.Fragment>
                  <div className="header" style={{fontWeight: '600'}}>{i18n.t('code_product')} #{product.code}</div>
                  <div className="description" dangerouslySetInnerHTML={{ __html: i18n.get_desc(product) }}></div>  
                  {/* {
                    product.discount && 
                    <div className='discount-main-banner'>{product.discount.percentage}% {i18n.t("discount_on_item_price")}</div>
                  } 
                  {
                    product.campain && 
                    <div className='discount-main-banner'>{i18n.get_generic(product.campain, 'caption')}</div>
                  }  */}
                </React.Fragment>
              }
            </div>

            {
              !fetchingProduct && product.prices &&
              <div className='quantities-section pt-4'>
                {product.mu_code === "DISPLAY" ? 
                <div className='inn'>
                <div className='details-pr'>
                  <label className='fw-bold'>{i18n.t('pieces_d')}</label>
                  <div className='product-quantities'>
                    <div className='decrease-btn'>
                      <button className='minus-step' onClick={() => changeQty(-1)}>-</button>
                    </div>
                    <input className='' type='number' min='1' onChange={() => {}} value={product.selected_quantity}></input>
                    <div className='increase-btn'>
                      <button className='plus-step' onClick={() => changeQty(+1)}>+</button>
                    </div>
                  </div>
                  </div>
                  </div>
                :
                <div className='inn'>
                  
                  <div className='details-pr'>
                  <label className='fw-bold'>{i18n.t('pieces_t')}</label>
                    <div className='product-quantities'>
                      <div className='decrease-btn'>
                        <button className='minus-step' onClick={() => changeQty(-1)}>-</button>
                      </div>
                      <input className='' type='number' min='1' onChange={() => {}} value={product.selected_quantity}></input>
                      <div className='increase-btn'>
                        <button className='plus-step' onClick={() => changeQty(+1)}>+</button>
                      </div>
                    </div>
                  
                  </div>
                  
                  <div className='details-pr'>
                  <label className='fw-bold'>{i18n.t('pieces_p')}</label>
                    <div className='product-quantities'>
                      <div className='decrease-btn'>
                        <button className='minus-step' onClick={() => changeQty(-1)}>-</button>
                      </div>
                      <input className='' type='number' min='1' onChange={() => {}} value={product.current_pckg}></input>
                      <div className='increase-btn'>
                        <button className='plus-step' onClick={() => changeQty(+1)}>+</button>
                      </div>
                    </div>
                  </div>
                </div>
                }
                <div className='quant-add pt-2'>
                  <button className="add-to-basket" onClick={ () => addToBasket()}>{i18n.t('add_to_basket')}</button>
                </div>
              </div>
            }
            {(latestOrders.length > 0) ? (
              <div className='details-pr latest-orders'>
                  <label className='latest-title'>{i18n.t('latest_orders')}</label>
                    <div className='latest-orders-wrapper'>
                    {latestOrders.map((latest_product, i) => {
                    return (
                      <div key={i} className='latest-order-container'>
                        <label htmlFor={`add${i}`} className='latest-order-date'>{`${latest_product.date}`}</label>
                        <button id={`add${i}`} className='latest-order-button' onClick={() => { product.selected_quantity = latest_product.quantity; addToBasket(); }}>{`${latest_product.quantity}`} <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-basket" class="svg-inline--fa fa-shopping-basket fa-w-18 fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-tip="true" data-for="controlsTip-basket" currentItem="false"><path fill="currentColor" d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"></path></svg></button>
                      </div>
                    )})}
                    </div>                    
              </div>
            ) : ( null )}

            <div className="controls">
              {/* <NumberPicker
                onChange={onCountChange}
              />

              <button
                type="button"
                className="btn btn-lg"
                // style={{backgroundColor: '#28a745'}}
                onClick={addToBasket}
              >
                <FontAwesomeIcon icon={faShoppingBasket} />
                &nbsp;
                {i18n.t('add_to_basket')}
              </button> */}

              <button
                type="button"
                className="btn btn-lg btn-outline fav-btn"
                onClick={addToFavourite}
              >
                <FontAwesomeIcon icon={faHeart} />
                <span className="d-none">Favourite</span>
              </button>
            </div>
          </div>
        </div>
          
        {
          !fetchingProduct && product.marketing_message &&
          <div>
              <div className="discount-message">
                {i18n.get_message(product.marketing_message)}
              </div>
          </div>
          
        }

        {
          !fetchingProduct && product.active_discounts && product.active_discounts.discount &&
          <div>
            {
              product.active_discounts &&
              <div className="discount-message">
                {i18n.t("by_buying")} {product.active_discounts.discount.qty} {i18n.t("pieces")} {i18n.t("you_earn")} {product.active_discounts.discount.discount_type === '1' ? i18n.t("discount_lower") + ' ' + product.active_discounts.discount.percentage + '% ' + i18n.t("for") : i18n.t("gift")} {i18n.t("same_qty_for_product")} #{product.active_discounts.discount.passive_product.code}
              </div>
            }
            <ProductDiscount discount={product.active_discounts.discount} show={showDiscount} closeDiscount={(e) => closeDiscount(e)} />
          </div>
          
        }
        {/* {
          !fetchingProduct && product.variants ?
          <ProductBasket product={product} onPriceSetChange={ (e) => onPriceSetChange(e)} onDiscountTrigger={ (e) => onDiscountTrigger(e) }/> : ''
        } */}

       
        <div className={`toast ${showToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={closeToast}
          />
          {i18n.t('product_added')}
          &nbsp;
          <Link to="/basket">{i18n.t('to_your_basket')}</Link>
        </div>
      </div>

      {/* //KARTELES ΚΑΡΤΕΛΕΣ
      <div className='row'>
        <div className='col-12'>
          <Tabs defaultActiveKey="first" className='justify-content-center'>
            <Tab eventKey="first" title={i18n.t('description')}>
              {
                !fetchingProduct && product.description && product.description.length > 0 && <React.Fragment>
                  <div className="description py-4" dangerouslySetInnerHTML={{ __html: i18n.get_desc(product) }}></div>  
                </React.Fragment>
              }
            </Tab>
            <Tab eventKey="second" title={i18n.t('more')}>
              Hii, I am 2nd tab content
            </Tab>
          </Tabs>
        </div>
      </div>
      */}


<div className="section py-4">
      {Array.isArray(relations) && relationTypes.map((relationType) => {
        const filteredProducts = relations.filter(
          (product) => product.relation_type_id === relationType.id
        );

        if (filteredProducts.length === 0) return null;
        //getPrice(filteredProducts.id, filteredProducts.cid);
        const transformedProducts = filteredProducts.map((product) => {
          const renamedProduct = {};

          for (const key in product) {
            if (Object.prototype.hasOwnProperty.call(product, key)) {
              renamedProduct[renamedAttributes[key] || key] = product[key];
            }
          }

          return renamedProduct;
        });

        return (
          <div key={relationType.id}>
            <div className="title-wrapper">
              <h3 className="title">{i18n.t('related_p')}</h3>
            </div>
            <Slider
              arrows={false}
              autoplay={true}
              autoplaySpeed={5000}
              adaptiveHeight={true}
              slidesToShow={4}
              slidesToScroll={4}
              responsive={[
                {
                  breakpoint: 1200,
                  settings: {
                    arrows: false,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                  },
                },
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {transformedProducts.map((product, index) => (
                <div key={index}>
                  <ProductItem
                    key={index}
                    data={product}
                    // onAddToBasket={() => addToBasket(product)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        );
      })}
    </div>
{/*
        <div className="section py-4">
          <div className='title-wrapper'>
            <h3 className="title">{i18n.t('related_p')}</h3>
          </div>
            <Slider {...{
              arrows: false,
              autoplay: true,
              autoplaySpeed: 5000,
              adaptiveHeight: true,
              slidesToShow: 4,
              slidesToScroll: 4,
              responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      arrows: false,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: false
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
            }}>
            {
              relatedProducts.map((product, i) => {
                return (
                  <ProductItem
                    key={i}
                    data={product}
                    // onAddToBasket={() => addToBasket(product)}
                  />
                );
              })
            }
            {
              relatedProducts.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
            </Slider>
        </div>
        return null;
      })}

        {/* //ΣΥΝΕΡΓΑΤΕΣ
        <div className="section py-4">
          <div className='title-wrapper'>
            <h3 className="title">{i18n.t('sam_p')}</h3>
          </div>
            <Slider {...{
              arrows: false,
              autoplay: true,
              autoplaySpeed: 5000,
              adaptiveHeight: true,
              slidesToShow: 4,
              slidesToScroll: 4,
              responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      arrows: false,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: false
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
            }}>
            {
              sameBrandProducts.map((product, i) => {
                return (
                  <ProductItem
                    key={i}
                    data={product}
                    // onAddToBasket={() => addToBasket(product)}
                  />
                );
              })
            }
            {
              sameBrandProducts.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
            </Slider>
        </div>
          */}
        
    </div>
     
  );
}

export default ProductPage;