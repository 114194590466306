import React, { useState, useEffect, useRef } from 'react'
import BrochurePage from './BrochurePage'
import { useParams } from 'react-router-dom';
import styles from './Brochure.module.css'
import './Brochure.css'
import request from '../request';
import { Tabs, Tab } from 'react-bootstrap'

const Brochure = () => {
  const { brochureId  } = useParams(); 
  const [pages, setPages] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {

    request.get(`api/prospectus/${brochureId}`).then((response) => {

      if(!request.response_valid(response)){
        return;
      }
        setPages(JSON.parse(response.data["content"]))
    });
  }, [brochureId]);

  useEffect(() => {
    //onDataChange(pages)
  }, [pages])

 
  const handlePageDelete = (index) => {
    const updatedPages = [...pages]
    updatedPages.splice(index, 1)
    setPages(updatedPages)
    setActiveTab(index - 1)
  }

  const handlePageUpdatePage = (updatedPages, index) => {
    const changedPages = [...pages]
    changedPages[index] = updatedPages    
    setPages(changedPages)
  }  

  const handlePageUpdateSections = (updatedSections, index) => {
    const updatedPages = [...pages]
    updatedPages[index].sections = updatedSections
    setPages(updatedPages)
  }

  return (
    <div className="brochure">

      <Tabs
      className='nav-fill'
      activeKey={activeTab}
      onSelect={(index) => setActiveTab(index)}
      id="pages-tabs"
      >        
        {pages?.map((page, index) => (
        <Tab
        eventKey={index}
        title={page.name}
        key={index}
        >          
          <BrochurePage
            key={index}
            page={page}
            index={index}
            onDelete={() => handlePageDelete(index)}
            onUpdatePage = {(updatedPages) => handlePageUpdatePage(updatedPages, index)}
            onUpdateSections={(updatedSections) => handlePageUpdateSections(updatedSections, index)}
          />
          </Tab>
        ))}
        </Tabs>
      </div>
  )

}

export default Brochure
