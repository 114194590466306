import './ProductAttributes.css';
import React from 'react';
import i18n from '../i18n';
import Select from 'react-select';

class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: {},
      filteredAttributes: {},
      selectedAttributes: {},
      uniqueVariant: false,
      currentAttribute: false,
    };
    
    this.renderAttributes = this.renderAttributes.bind(this);
    this.onAttributeSelect = this.onAttributeSelect.bind(this);
    this.onAttributeClick = this.onAttributeClick.bind(this);
    this.filterAttributes = this.filterAttributes.bind(this);
  }

  componentDidMount() {
    this.renderAttributes();

    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.variants !== this.props.data.variants) {
      this.renderAttributes();
    }
  }

  renderAttributes() {
    const groupedAttributes = {};

    if ('variants' in this.props.data) {
      const allAttributes = [];
      this.props.data.variants.forEach((variant) => {
        variant.attributes.forEach((attribute) => {
          if (allAttributes.findIndex((a) => a.value_id === attribute.value_id) === -1) {
            allAttributes.push(attribute);
          }
        });
      });

      allAttributes.forEach((attribute) => {
        if (!(attribute.attribute_category_id in groupedAttributes)) {
          groupedAttributes[attribute.attribute_category_id] = {}
          groupedAttributes[attribute.attribute_category_id].values = [];
          groupedAttributes[attribute.attribute_category_id].name = attribute.attribute_category;
          groupedAttributes[attribute.attribute_category_id].ref = React.createRef();
        }

        groupedAttributes[attribute.attribute_category_id].values.push({
          label: attribute.value,
          value: attribute.value_id,
          custom_value: attribute.custom_value
        });
      });
    }
    if(typeof groupedAttributes[1] !== 'undefined'){
      groupedAttributes[1].values.sort( (a, b) => {return a.value - b.value} )
    }
    this.setState({
      ...this.state,
      attributes: groupedAttributes,
      filteredAttributes: groupedAttributes,
    });
  }

  filterAttributes() {
    let filteredAttributes = {};
    let uniqueVariantId = true;
    if (Object.keys(this.state.selectedAttributes).length === 0) {
      filteredAttributes = { ...this.state.attributes };
    } else {
      // this.props.data.variants.forEach((variant) => {
      //   variant.attributes.forEach((attribute) => {
      //     if (allAttributes.findIndex((a) => a.value_id === attribute.value_id) === -1) {
      //       allAttributes.push(attribute);
      //     }
      //   });
      // });
      
      Object.keys(this.state.attributes).map((attributeId) => { //λουπα στις κατηγοριες
        
        if(typeof filteredAttributes[attributeId]  == 'undefined'){
          filteredAttributes[attributeId] = {};
          filteredAttributes[attributeId].values = [];
          filteredAttributes[attributeId].name = this.state.attributes[attributeId].attribute_category;
        }

        filteredAttributes[attributeId] = filteredAttributes[attributeId] || {}; //σετταρουμε αν δεν υπαρχει 
        const isSelectedAttribute = typeof this.state.selectedAttributes[attributeId] !== 'undefined' && this.state.selectedAttributes[attributeId] !== null; //κοιταμε αν η κατηγορια έχει selected τιμή
        if (isSelectedAttribute) { // αν εχει selected τιμη η κατηγορια εκτελεσε τον κωδικα
          const selectedAttributeValue = this.state.selectedAttributes[attributeId].value; //παρε την selected τιμη της κατηγοριας 
          this.props.data.variants.forEach((variant) => {
            const attributeExistsInVariantAttributes = variant.attributes.findIndex((a) => a.attribute_category_id === attributeId && a.value_id === selectedAttributeValue) > -1;
            if (attributeExistsInVariantAttributes) { 
              variant.attributes.forEach((attribute) => {
                if (typeof filteredAttributes[attribute.attribute_category_id] === 'undefined') {
                  filteredAttributes[attribute.attribute_category_id] = {};
                  filteredAttributes[attribute.attribute_category_id].values = [];
                  filteredAttributes[attribute.attribute_category_id].name = attribute.attribute_category;
                }
                const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category_id].values.findIndex((o) => o.value === attribute.value_id) > -1;
                if (!attributeAlreadyAdded && this.state.currentAttribute != attribute.attribute_category_id) {
                  filteredAttributes[attribute.attribute_category_id].values.push({
                    label: attribute.value,
                    value: attribute.value_id,
                    custom_value: attribute.custom_value
                  });
                }
              });
            }
          });
        }
      });
      if(this.state.currentAttribute){
          let gotAttributes = false;
          Object.keys(this.state.attributes).map((attributeId) => { //λουπα στις κατηγοριες
            if(attributeId != this.state.currentAttribute){
              const isSelectedAttribute = typeof this.state.selectedAttributes[attributeId] !== 'undefined' && this.state.selectedAttributes[attributeId] !== null; //κοιταμε αν η κατηγορια έχει selected τιμή
              if (isSelectedAttribute) { // αν εχει selected τιμη η κατηγορια εκτελεσε τον κωδικα
                const selectedAttributeValue = this.state.selectedAttributes[attributeId].value; //παρε την selected τιμη της κατηγοριας 
                gotAttributes = true;
                this.props.data.variants.forEach((variant) => {
                  const attributeExistsInVariantAttributes = variant.attributes.findIndex((a) => a.attribute_category_id === attributeId && a.value_id === selectedAttributeValue) > -1;
                  if (attributeExistsInVariantAttributes) { 
                    variant.attributes.forEach((attribute) => {
                      if (typeof filteredAttributes[attribute.attribute_category_id] === 'undefined') {
                        filteredAttributes[attribute.attribute_category_id] = {};
                        filteredAttributes[attribute.attribute_category_id].values = [];
                      }
                      const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category_id].values.findIndex((o) => o.value === attribute.value_id) > -1;
                      if (!attributeAlreadyAdded) {
                        filteredAttributes[attribute.attribute_category_id].values.push({
                          label: attribute.value,
                          value: attribute.value_id,
                          custom_value: attribute.custom_value
                        });
                      }
                    });
                  }
                });
              }
            }
          });
          if(!gotAttributes){
            this.props.data.variants.forEach((variant) => {
              variant.attributes.forEach((attribute) => {
                if(attribute.attribute_category_id == this.state.currentAttribute){
                  if (typeof filteredAttributes[attribute.attribute_category_id] === 'undefined') {
                    filteredAttributes[attribute.attribute_category_id] = {};
                    filteredAttributes[attribute.attribute_category_id].values = [];
                  }
                  const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category_id].values.findIndex((o) => o.value === attribute.value_id) > -1;
                  if (!attributeAlreadyAdded) {
                    filteredAttributes[attribute.attribute_category_id].values.push({
                      label: attribute.value,
                      value: attribute.value_id,
                      custom_value: attribute.custom_value
                    });
                  }
                }
              });
            });
          }
          
      }
      // this.state.attributes.filter( (a) => {
        
      // })
      let shouldSkip = false;
      this.props.data.variants.forEach((variant) => {
        
        if(shouldSkip) return false;

        uniqueVariantId = variant;
        variant.attributes.forEach((attribute) => {
          const isSelectedAttribute = typeof this.state.selectedAttributes[attribute.attribute_category_id] !== 'undefined' 
                                      && this.state.selectedAttributes[attribute.attribute_category_id] !== null //κοιταμε αν η κατηγορια έχει selected τιμή
                                      && this.state.selectedAttributes[attribute.attribute_category_id].value == attribute.value_id;
            
          if (!isSelectedAttribute) {
            uniqueVariantId = false;
            return;
          }
        } );
        
        if(uniqueVariantId){
          shouldSkip = true;
        }
      } );
      if(uniqueVariantId){
        this.props.onVariantChange(uniqueVariantId);
      }
    }
    if(typeof filteredAttributes[1] !== 'undefined'){
      filteredAttributes[1].values.sort( (a, b) => {return a.value - b.value} )
    }
    this.setState({
      ...this.state,
      filteredAttributes: filteredAttributes,
      uniqueVariant: uniqueVariantId
    });
  }

  onAttributeSelect(attribute, option) {
    const sa = {
      ...this.state.selectedAttributes,
    };
    sa[attribute] = option;
    this.setState({
      ...this.state,
      selectedAttributes: sa,
      currentAttribute: attribute
    });

    setTimeout(() => {
      this.filterAttributes();
    });
    if(attribute == 1){
      if( (typeof this.props.onChangeImage != "undefined") ){
        this.props.onChangeImage(attribute)
      }
    }
  }

  onAttributeClick(attribute, option, e){
    document.querySelectorAll(".color-value").forEach( (a) => {
      a.classList.remove("active");
    });
    e.target.classList.add("active")
    const sa = {
      ...this.state.selectedAttributes,
    };
    sa[attribute] = option;
    this.setState({
      ...this.state,
      selectedAttributes: sa,
      currentAttribute: attribute
    });
    setTimeout(() => {
      this.filterAttributes();
    });
    if(attribute == 1){
      if( (typeof this.props.onChangeImage != "undefined") ){
        this.props.onChangeImage(option)
      }
    }
  }

  render() {
    
    return (
      <div className="ProductAttributes">
        {
          Object.keys(this.state.attributes).map((attribute, i) => {
            if(attribute != 1){
              return (
                <Select
                  key={i}
                  className="select"
                  options={this.state.filteredAttributes[attribute].values}
                  placeholder={`${i18n.t('choose')} ${this.state.filteredAttributes[attribute].name}`}
                  value={this.state.selectedAttributes[attribute]}
                  onChange={(selectedOption) => this.onAttributeSelect(attribute, selectedOption)}
                  ref={this.state.attributes[attribute].ref}
                />
              );
            }else{
              return (
                <div className="color-pick">
                  {
                    this.state.filteredAttributes[attribute].values.map( (color) => {
                      if(color.custom_value != null && color.custom_value != ''){
                        if(color.custom_value.indexOf("|") > -1){
                          let colors = color.custom_value.split("|");
                          
                            return (
                              <div 
                                className="color-value" 
                                onClick={(e) => this.onAttributeClick(attribute, color, e)}
                              >
                                {
                                  colors.forEach( (c) => {
                                    <div className='color-half' style={{background: `#${c}`}}></div>
                                  })
                                }
                              </div>
                            )
                        }else{
                          return (
                            <div 
                              className="color-value" 
                              style={{background: `#${color.custom_value}`}}
                              onClick={(e) => this.onAttributeClick(attribute, color, e)}
                            >
    
                            </div>
                          )
                        }
                        
                      }
                      
                    })
                  }
                  
                </div>
              );
            }
            
          })
        }

        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> */}
      </div>
    );
  }
}

export default ProductAttributes;