import React from "react";
import {Link,} from 'react-router-dom';
import i18n from "../i18n";
import Select from 'react-select';

class ProductsOrdering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            type: null
        }
    }

       handleChange = (selectedOption) => {
        this.props.onOptionChange(selectedOption.value, this.props.type);
        this.setState({ selectedOption });
    };
    
    render() {
        const { selectedOption } = this.state;
        const options = [
            { value: 'title', label: i18n.t('brand_low_to_high') },
            { value: 'code', label: i18n.t('code_low_to_high') }
        ];
        return (
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={options}
                placeholder={i18n.t('order_by')}
            />
        )
    }

}
export default ProductsOrdering;