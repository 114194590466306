import './BrochureItem.css';
import React from 'react';
import i18n from '../i18n';
import { Link } from 'react-router-dom';

class BrochureItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        id: this.props.data.id,
        title: this.props.data.name,
        image: this.props.data.thumbnail,
    }
  }

  render() {
    return(
    <div key={this.state.id}>
      <Link to={`/brochure/${this.state.id}`} className="brochure-item-link">
        <div className="brochure-item">
            <div className="brochure-item-title">
                <h3>{this.state.title}</h3>
            </div>

            <div className="brochure-item-image">
                <img src={this.state.image} alt={this.state.title} />
            </div>
        </div>
      </Link>
    </div>
    )
  }

}

export default BrochureItem;