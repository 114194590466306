import './ProductColors.css';
import React from 'react';
import i18n from '../i18n';
import ReactTooltip from "react-tooltip";

class ProductColors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: props.data,
    };
    
    this.onAttributeClick = this.onAttributeClick.bind(this);
  }

  componentDidMount() {
    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  onAttributeClick(option, e){
    document.querySelectorAll(".color-value").forEach( (a) => {
      a.classList.remove("active");
    });
    e.target.classList.add("active")
    if( (typeof this.props.onChangeImage != "undefined") ){
      this.props.onChangeImage(option)
    }
  }

  render() {
    
    return (
      <div className="ProductColors">
        {
         
          <div className="color-pick">
            {
              this.props.data ?
              this.props.data.map( (color, i) => {
                if(color.custom_value != null && color.custom_value != '' && color.url){
                  let colors = [];
                  let grad;
                  if(color.custom_value.indexOf("|") > -1){
                    colors = color.custom_value.split("|");
                    let percentages = [];
                    
                    let percent = Math.floor(100 / colors.length); 
                    grad = 'linear-gradient(45deg';
                    colors.forEach( (c, i) => {
                      if(colors.length > 1){
                        if(i == 0){
                          grad += ', #' + c + " " + (percent + Math.floor(percent / 3) ) + "%";
                        }else{
                          grad += ', #' + c + " " + (percent - Math.floor(percent / (colors.length + 1) ) ) + "%";
                        }
                      }else{

                      }
                      grad += ', #' + c + " " + percent + "%";
                    } )
                    grad += ')';
                  }else{
                    grad = "#" + color.custom_value;
                  }
                  
                  //linear-gradient(to right top, #ff0000, #f36300, #de9200, #c2b700, #a0d600, #74dd54, #46e085, #00e1ad, #00c6d9, #00a5ff, #0078ff, #0000ff)
                    return (
                      <div key={i}
                        className="color-value" 
                        style={{background: grad}}
                        onMouseEnter={(e) => this.onAttributeClick(color, e)}
                        data-tip data-for={`registerTip-${color.value}`}
                      >
                        <ReactTooltip id={`registerTip-${color.value}`} place="top" effect="solid">
                          {color.label}
                        </ReactTooltip>
                      </div>
                      
                    )
                  //}
                }else if(color.url){
                  let grad = `url('${color.url}') 40% 40%`;
                  
                  //linear-gradient(to right top, #ff0000, #f36300, #de9200, #c2b700, #a0d600, #74dd54, #46e085, #00e1ad, #00c6d9, #00a5ff, #0078ff, #0000ff)
                    return (
                      <div key={i}
                        className="color-value" 
                        style={{background: grad}}
                        onMouseEnter={(e) => this.onAttributeClick(color, e)}
                        data-tip data-for={`registerTip-${color.value}`}
                      >
                        <ReactTooltip id={`registerTip-${color.value}`} place="top" effect="solid">
                          {color.label}
                        </ReactTooltip>
                      </div>
                      
                    )
                }
              }) :
              ''
            }
            
          </div>
        }
        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> */}
      </div>
    );
  }
}

export default ProductColors;