import './ProductDiscount.css';
import '../product-page/ProductPage'
import React, {  } from 'react';
import request from '../request';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingBasket, faTimes, faPaperPlane, faBasketballBall, faRedo} from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import ProductColors from '../product-colors/ProductColors';
import Product360 from '../product-360/Product360';
import ProductDiscountBasket from '../product-discount-basket/ProductDiscountBasket'




class ProductDiscount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      imageView: 'colors',
      discount: this.props.discount,
    };

    this.elementRef = React.createRef();
  }

  componentDidMount() {
    this.getDiscountProduct()
  }

  getDiscountProduct() {
    request.post(`api/product-discount/${this.state.discount.id}`, false ).then((response) => {
      const productUpdated = {
        id: response.data.id,
        name: response.data.title,
        name_en: response.data.title_en,
        thumbnail: response.data.thumbnail,
        code: response.data.code,
        miniDescription: response.data.mini_description,
        miniDescription_en: response.data.mini_description_en,
        description: response.data.description,
        description_en: response.data.description_en,
        image: response.data.thumbnail,
        rating: response.data.rating,
        price: response.data.price,
        availability: response.data.availability,
        variants: response.data.variants,
        colors: response.data.colors.filter(c => 
          response.data.variants.findIndex(v => v.attributes.findIndex(a => a.value_id == c.value) !== -1) !== -1
        ),
        i360: response.data.i360,
        prices: response.data.prices,
        current_price: null,
        picking_package: response.data.picking_package,
        picking_price: response.data.picking_price,
        picking_limit: response.data.picking_limit,
        passive_discounts: response.data.passive_discounts,
        active_discounts: response.data.active_discounts,
      };
      
      this.setState({
        ...this.state,
        product: productUpdated,
      }, )
    });
  }
  setImageView(e) {
    this.setState({
      ...this.state,
      imageView: e
    })
  }

  onChangeImage(p) {
    if(p.url && this.state){
      const prod = this.state.product;
      prod.thumbnail = p.url
      this.setState({
        ...this.state,
        product: prod,
      });
    }
  }

  closeDiscount() {
    this.props.closeDiscount(this.state.discount.id)
  }

  render(){
    return this.state.product && this.props.show ?
    <div className='discount-wrapper'>
      <div className='discount-container discount-container-lg'>
        <button className="close-discount-btn" onClick={() => this.closeDiscount()}><FontAwesomeIcon icon={faTimes}/> <br/></button>
        <div className='discount-inner'>
          <div className="discount-header">{i18n.t("you_received")} {this.state.discount.discount_type == '1' ? i18n.t("discount_lower")+' ' + this.state.discount.percentage + '% ' + i18n.t("for") : i18n.t("gift")} {this.state.discount.total_qty} {i18n.t("qty_for_product")} #{this.state.product.code} {i18n.t("for_specific_variants")}</div>
          <div className={`basic-info`}>
            <div className="image">
              {
                this.state.product.i360 && this.state.product.i360.length > 0 && this.state.product.colors &&
                <div className="images-controls">
                  <button onClick={ () => this.setImageView("360")}  className={`${this.state.imageView == "360" && "active"}`}>
                    <FontAwesomeIcon icon={faRedo}/> <br/>
                    360°
                  </button>
                  <button onClick={ () => this.setImageView("colors")} className={`color-btn ${this.state.imageView == "colors" && "active"}`}>
                  </button>
                </div>
              }
              
              <div className={`images-view color-image-view ${this.state.imageView != "colors" && "hidden"}`}>
                <img src={this.state.product.thumbnail} alt={this.state.product.name} />
                <div className="mt-5">
                  <ProductColors data={this.state.product.colors} onChangeImage={ (e) => this.onChangeImage(e)}/>
                </div>
              </div>
              {
                this.state.product.i360 && this.state.product.i360.length > 0 &&
                <div className={`images-view i360-image-view ${this.state.imageView != "360" && "hidden"}`}>
                  <div className="mt-5">
                    <Product360 data={this.state.product.i360}/>
                  </div>
                </div>
              }
            </div>
            <div className="details">
            <span className="name">{i18n.get_name(this.state.product)}</span>
          
              {/* <div className="price">
                <span className="current-price">{calculatePrice()}&nbsp;€</span>
              </div> */}
              
              <div className={`extra-info`}>
                {
                  this.state.product.description && this.state.product.description.length > 0 && <React.Fragment>
                    <div className="header" style={{fontWeight: '600'}}>#{this.state.product.code}</div>
                    <div className="description" dangerouslySetInnerHTML={{ __html: i18n.get_desc(this.state.product) }}></div>   
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
          <ProductDiscountBasket product={this.state.product} discount={this.state.discount}/>
        </div>
      </div>
    </div>
    :
    ''
  } 
}

export default ProductDiscount;