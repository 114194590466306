import './Product360.css';
import React from 'react';
import i18n from '../i18n';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

class Product360 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: props.data,
      total: props.data ? props.data.length : 0,
      current_pos: props.data ? 0 : false,
      tick: props.data ? 3000 / props.data.length : false,
      paused: props.play ? false : true,
      per_click: props.data ? props.data.length / 8 : false,
      completed_click: false,
      rotate: false
    };
    
  }

  timer = () => setInterval(() => { // return the timeoutID
    let id = this.state.current_pos + 1
    if(id >= this.state.total){
      id = 0
    }
    this.setState({
      ...this.state,
      current_pos: id,
    });

    this.forceUpdate();
  }, this.state.tick);

  

  setIntervalLimited(x, e) {

    for (var i = 0; i < x; i++) {
      setTimeout(() => {
        let id = this.state.current_pos + e
        if(id >= this.state.total){
          id = 0
        }
        if(id < 0){
          id = this.state.total - 1
        }
        this.setState({
          ...this.state,
          current_pos: id,
        });
    
        this.forceUpdate();
      }, this.state.tick * i);
    }

  }

  componentDidMount() {
    if(this.state.images){
      
      this.setState({
        ...this.state,
      })
      if(this.props.play)
        this.timeoutID = this.timer()
    }
    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
    if(this.props.play && !prevProps.play && prevState.paused){
      clearInterval(this.timeoutID)
      this.timeoutID = this.timer()
      this.setState({
        ...this.state,
        paused: false
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeoutID)
  }

  pauseClick(){
    let paused = this.state.paused;
    clearInterval(this.timeoutID)
    if(paused){
      this.timeoutID = this.timer()
      this.setState({
        ...this.state,
        paused: false,
      })
    }else{
      this.setState({
        ...this.state,
        paused: true,

      })
    }
  }

  leftClick(){
    this.setIntervalLimited(this.state.per_click, -1)
  }

  rightClick(){
    this.setIntervalLimited(this.state.per_click, 1)
  }

  render() {
    
    return (
      
      this.state.images ?
        <div className="Product360">
            <div>
              <img src={this.state.current_pos !== false && this.state.images[this.state.current_pos].url} />
            </div>
            <div className="i360-controls" style={{textAlign: "center"}}>
              <button onClick={ () => this.leftClick()}>
              <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button onClick={ () => this.pauseClick()}>
              <FontAwesomeIcon icon={this.state.paused ? faPlay : faPause} />
              </button>
              <button onClick={ () => this.rightClick()}>
              <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
        </div> :
      ''
    );
  }
}

export default Product360;