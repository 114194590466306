import './AboutUs.css';
import React from 'react';
import i18n from '../i18n';
import request from '../request';
import history from '../history';
import { useParams } from "react-router-dom";


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class AboutUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            id: this.props.params.id,
            years: new Date().getFullYear(),
            experienceyears: '',
        };

        this.getArticles = this.getArticles.bind(this);
    }
    
    onLanguageChange = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        document.title = i18n.t("about_us_page_title");
        this.onHistoryChangeUnlisten = history.listen(() => {
            this.setState({
                ...this.state,
                id: this.props.params.id
            }, () => {
                this.getArticles()
            });
        });
        this.experienceYears();
        this.getArticles()
        this.unlistenOnLanguageChange = i18n.onLanguageChange(this.onLanguageChange);
    }

    componentWillUnmount() {
        this.unlistenOnLanguageChange();
    }
    
    getArticles() {
        request.get(`api/article/128`).then((response) => {
            this.setState({
                ...this.state,
                articles: response.data
            });
            
        });
    }
    experienceYears() {
        this.setState({
            experienceyears: this.state.years - 2000,
        })
        // console.log(this.state.experienceyears)
        // return this.state.experienceyears
    }
    
    render() {
        return (
            <div className='column container bg-white'>
                <div class='row'>
                    <div id="terms-wrapper" style={{minHeight: "60vh"}}>
                        {this.state.articles.map( (article) => {
                            return(
                                <div className='content'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <h3 className='title text-center'>{i18n.toggle(article.title, article.title_en)}</h3>
                                            <div className='term-content'>
                                                <p id="terms-wrapper" dangerouslySetInnerHTML={{__html: i18n.toggle(article.content, article.content_en)}}>
                                                </p>
                                                <hr></hr>
                                                <div className='years'>
                                                    <span>{this.state.experienceyears}</span>
                                                    <div class='text-year'>
                                                        <p><strong>{i18n.t('experience')}</strong></p>
                                                        <p>Πάνω από 2.000 πελάτες και συνεργάτες σε Ελλάδα και Κύπρο.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='img-right'>
                                                <img style={{maxWidth:'100%'}} src='https://cdn.pixabay.com/photo/2016/03/30/22/58/retro-1291745_960_720.jpg'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            </div>
        );
    }
}
export default withParams(AboutUs);