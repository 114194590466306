import React, { useState, useRef, useEffect } from 'react'
import Section from './Section'
import styles from './BrochurePage.module.css'
//import Images from './Image'

function BrochurePage(props) {
  const [pageName, setPageName] = useState(props.page.name || null)
  const [background, setBackground] = useState(props.page.background || null)
  const [sections, setSections] = useState(props.page.sections || [])
 
  useEffect(() => {
    props.onUpdatePage({ ...props.page, name: pageName, background})
  }, [pageName, background])

  const handleSectionDelete = (index) => {
    const updatedSections = [...sections]
    updatedSections.splice(index, 1)
    setSections(updatedSections)
    props.onUpdateSections(updatedSections, props.index)
  }

  return (
    <>

    <div style={{ backgroundImage: `url(${props.page.background})`, aspectRatio: '210/297' }} className={styles["brochure-page"]}>
      {props.page.name ? (<h2 className={styles["section-title"]}>{props.page.name}</h2>) : null}
      {sections.map((section, index) => (
        <Section
          key={index}
          section={section}
          index={index}
          onDelete={() => handleSectionDelete(index)}
          onUpdateProducts={(updatedProducts) => {
            const updatedSections = [...sections]
            updatedSections[index] = updatedProducts
            setSections(updatedSections)
            props.onUpdateSections(updatedSections, props.index)
          }}
        />
      ))}
    </div>
    </>
  )
}

export default BrochurePage
