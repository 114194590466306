import './Footer.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons'
import storage from '../storage';
import logoImage from '../assets/img/logo.png';
import request from "../request";
import gtag_evt from '../gtag';
import GoToTopButton from './GoToTopButton';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIartsLink: false,
      terms: [],
      support: [],
      policies: [],
      me: storage.me,
      newsletterEmail: ''
    };

    this.elementRef = React.createRef();
  }

  

  componentDidMount() {
    storage.onChange(() => {
      this.setState({
        ...this.state,
        me: storage.me
      })
    })
    request.get(`api/terms-of-use`).then((response) => {
      if(!response) return;
      this.setState({
        terms: response.data
      })
    });

    request.get(`api/articles/5`).then((response) => {
      if(!response) return;
      this.setState({
        support: response.data.articles ? response.data.articles : []
      })
    });

    request.get(`api/articles/6`).then((response) => {
      if(!response) return;
      this.setState({
        policies: response.data.articles ? response.data.articles : []
      })
    });
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }
  getYear() {
    return new Date().getFullYear();
  }

  sendGtag() {
    gtag_evt.gtag('event', 'newsletter_submit', {
      email: this.state.newsletterEmail,
    })
  }
  render() {
    return (
      <>
      <GoToTopButton />
      <div className='newsltter-section'>
        <div className='container'>
          <div className='row'>
              <div className='subscribe-form'>
                <h4 className="info-text">{i18n.t('newsletter_info')}</h4>
                <form action="https://dealmarkets.us6.list-manage.com/subscribe/post?u=d35a4629e6f93f7de74ca4e22&amp;id=5967694000" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                  <div className="email-input">
                    {/* <label htmlFor="subscribe-email-input-newsletter">subsribe email input</label> */}
                    <input type="email" value={this.state.newsletterEmail} onChange={(e) => this.setState({...this.state, newsletterEmail: e.target.value})} name="EMAIL" className="required email" id="subscribe-email-input-newsletter" />
                  
                    <input type="hidden" name="tags" defaultValue="3252659" />
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                      <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                    </div>    
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_d35a4629e6f93f7de74ca4e22_5967694000" tabIndex="-1" defaultValue="" /></div>
                    <div className="clear">
                      <button type="submit" onClick={() => this.sendGtag()} value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" >{i18n.t('subscribe')}</button>
                    </div>
                    
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer"
        ref={this.elementRef}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center pt-4'>
              <div className="logo">
                <Link to="/">
                  <img src={logoImage} width="175" alt="DealMarkets" />
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
              <div className="sections">
                <div className="about">
                  <span className='title-f'>{i18n.t('addressf')}</span>
                  <div className="address">{i18n.get_text(storage.company.address)}, {i18n.get_text(storage.company.city)}, {storage.company.zip}</div>
                  <span className='title-f'>{i18n.t('gemi')}</span>
                  <div className="phone">{storage.company.gemi}</div>
                  <span className='title-f'>EMAIL</span>
                  <div className="email">
                    <span>{storage.company.email1}</span>
                  </div>
                  <span className='title-f'>{i18n.t('phonef')}</span>
                  <div className="phone">{storage.company.phone1}</div>
                  <span className='title-f'>{i18n.t('hoursf')}</span>
                  <div className="hours">{i18n.t('monday_to_friday_and_hours')}</div>
                  <span className='title-f'>SOCIAL</span>
                  <div className="social">
                      {
                        storage.company.social_facebook !== '' ?
                        <a className="social-links" href={storage.company.social_facebook}><FontAwesomeIcon icon={faFacebook} /></a> :
                        ''
                      }
                      {
                        storage.company.social_instagram !== '' ?
                        <a className="social-links" href={storage.company.social_instagram}><FontAwesomeIcon icon={faInstagram} /></a> :
                        ''
                      }
                      {
                        storage.company.social_pinterest !== '' ?
                        <a className="social-links" href={storage.company.social_pinterest}><FontAwesomeIcon icon={faPinterest} /></a> :
                        ''
                      }
                  </div>
                </div>
                <div className="support">
                  <h3>{i18n.t('support')}</h3>
                  {/* <Link to="/contact">{i18n.t('contact_us')}</Link>
                  <Link to="">{i18n.t('feedback')}</Link>
                  <Link to="">{i18n.t('unsubscribe')}</Link> */}
                  {
                    this.state.support.map((term, i) => {
                      return(
                              <Link key={i} to={`/terms/${term.id}`}>{i18n.get_title(term)}</Link>
                            )
                    })
                    // this.state.terms.slice(0,this.state.terms.length / 2).map( (term, i) => {
                    //     return(
                    //       <Link key={i} to={`/terms/${term.id}`}>{i18n.get_title(term)}</Link>
                    //     )
                      
                    // } )
                  }
                </div>
                <div className="policies">
                  <h3>{i18n.t('policies')}</h3>
                  {
                    this.state.policies.map((term, i) => {
                      return(
                              <Link key={i} to={`/terms/${term.id}`}>{i18n.get_title(term)}</Link>
                            )
                    })
                    // this.state.terms.slice((this.state.terms.length / 2) + 1,this.state.terms.length).map( (term, i) => {
                    //     return(
                    //       <Link key={i} to={`/terms/${term.id}`}>{i18n.get_title(term)}</Link>
                    //     )
                      
                    // } )
                  }
                  {/* <Link to="">{i18n.t('privacy_policy')}</Link> */}
                  {/* <Link to="/terms">{i18n.t('terms_of_use')}</Link>
                  <Link to="">{i18n.t('shipping')}</Link> */}
                  {/* <Link to="">{i18n.t('return_policy')}</Link> */}
                </div>
                {/*
                <div className="subscribe">
                 <h3>{i18n.t('stay_up_to_date')}</h3>
                  
                  {/* {
                    this.state.me.catalogs && this.state.me.catalogs.length > 0 &&  
                    <span className='title-f'>{i18n.t('catalogs_upper')}</span>
                  
                  } */}
                  {/* {
                    this.state.me.catalogs && this.state.me.catalogs.length > 0 &&  
                    <div className="hours" style={{marginTop: "15px"}}>{
                      this.state.me.catalogs.map(c => {
                        return <a style={{display: 'inline-block', color: '#606060', textDecoration: 'none', perspective: "115px"}} href={`https://manager.dealmarkets.eu/files/${c.url}`} target="_blank">
                          <img src={`https://manager.dealmarkets.eu/files/${encodeURIComponent(c.thumbnail)}`} style={{width: "115px", transform: "rotateY(20deg)", boxShadow: "-7px 0 5px 0 rgb(0 0 0 / 35%)"}}/>  
                        </a>
                      })
                    }</div>
                  
                  } * /}
                  
                </div>
                */}
              </div>
          </div>
        </div>
        <div className="bar">
        <a href="https://iarts.gr">
                  Copyright © {this.getYear()}, iarts.gr All Rights Reserved
        </a>
              
          
        </div>
      </div>
      </>
    );
  }
}

export default Footer;