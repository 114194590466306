import './ProductPrices.css';
import React from 'react';
import i18n from '../i18n';

class ProductPrices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.price && props.data ? props.price : props.data[0].price
    }
  }

  componentDidMount() {
    this.unmount = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unmount()
  }


  render() {
    let cur_price = this.props.price && this.props.data ? this.props.price : this.props.data[0].price
    return (
    this.props.data.map( (price,i) => {
      return(
        <div classNam="prices">
          <span className="old-price-li">{price.old_price && price.old_price.toFixed(2) + ' €'}</span>
          <span>{price.price.toFixed(2)} €</span>
        </div>
      )
    })
    )
    /*
    let cur_price = this.props.price && this.props.data ? this.props.price : this.props.data[0].price
    return (
      <div className="ProductPrices table-responsive">
        {
         
          <table className="price-pick table ">
            {
              this.props.data ?
              <tbody>
                <tr className='price-row header-prices'>
                  <th>{i18n.t("from_qty_header")} ({i18n.t("pcs")})</th>
                  <th colSpan="2">{i18n.t("price_header")}</th>
                </tr>
                {
                this.props.data.map( (price,i) => {
                  return(
                    <tr key={i} className={`price-row ${cur_price == price.price ? 'active' : ''}`}>
                      <td>{i18n.t("from_qty")} {price.qty} {i18n.t("pcs")}</td>
                      <td>
                        <table>
                        <tbody>
                          <tr>
                            <td className="old-price-li">{price.old_price && price.old_price.toFixed(2) + ' €'}</td>
                            <td>{price.price.toFixed(2)} €</td>
                          </tr>
                        </tbody>
                        </table>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
              :
              <tbody></tbody>
            }
          </table>
        }
        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> * /}
      </div>
    );*/
  }
}

export default ProductPrices;