import request from './request';
import discountBasket from './discountBasket';

class Basket {
  constructor() {
      this.initializeBasket()
  }
  basket_init = false;
  products = [];
  invalidProducts = [];
  onAddProductFns = [];
  onRemoveProductFns = [];
  onChangeFns = [];
  onLoadFns = [];

  validateBasketStorage() {
    let basket = localStorage.getItem('basket');
    if (basket) {
      basket = JSON.parse(basket);
    } else {
      basket = [];
    }

    if(basket.length == 0){
      localStorage.setItem('basket', '[]')
      return
    }

    request.post(`api/product-basket-validate`, {basket: basket}, false).then((response) => {

      if(!response.data) return;
      this.invalidProducts = [];
      response.data.invalid_basket.forEach(product => {
        if(this.invalidProducts.filter(p => p.id == product.id && p.variant_id == product.variant_id).length > 0) return;
        let variant_id = 0;
        if(product.selectedVariant){
          variant_id = product.selectedVariant.id;
        }
        let selectedVariant = false;
        let title = product.title;
        let title_en = product.title_en;
        if(variant_id > 0){
          if(product.variants){
            selectedVariant = product.variants[product.variants.findIndex( (v) => v.id === variant_id)];
            if (typeof selectedVariant !== 'undefined' && selectedVariant.attributes) {
              title += " (";
              title_en += " (";
              selectedVariant.attributes.forEach( (a) => {
                title += `${a.attribute_category}: ${a.value} `;
                title_en += `${a.attribute_category_en}: ${a.value} `;
              });
              title += " )";
              title_en += " )";
            }
            
          }
        }
        this.invalidProducts.push({
          ...product,
          name: title,
          name_en: title_en,
          thumbnail: this.getImage(product, selectedVariant),
          selectedVariant: selectedVariant,
          variant_id: variant_id,
        });
        let productsIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id === variant_id);
        if(productsIndex > -1) {
          this.products.splice(productsIndex, 1);
        }
        // this.removeProduct(product, variant_id)
      })
      this.onChangeFns.forEach((fn) => fn());
    })
  }

  initializeBasket(force_update = false, forced_basket = ""){
    
    request.get_user_status().then( (r) => {
      if(r){//test
        if(this.basket_init && !force_update) return;
        this.basket_init = true;
        let localstorageProducts = localStorage.getItem('basket');
        if (forced_basket != "") {
          localstorageProducts = forced_basket;
        }
        if (localstorageProducts) {
          try{
            localstorageProducts = JSON.parse(localstorageProducts);
          }catch(e){
            localStorage.setItem('basket', '[]');
            localstorageProducts = [];
          }
        } else {
          localstorageProducts = [];
        }
        let promises = [];
        this.products = [];
        
        request.post(`api/get_basket_products`, {
          basket: localstorageProducts
        }, false).then((response) => {
          if(!response.data) return;

          response.data.basket.forEach(product => {
            let variant_id = 0;
            if(this.products.filter(p => p.id == product.id && p.variant_id == product.variant_id).length > 0) return;
            if(product.selectedVariant){
              variant_id = product.selectedVariant.id;
            }
            let selectedVariant = false;
            let title = product.title;
            let title_en = product.title_en;
            if(variant_id > 0){
              if(product.variants){
                selectedVariant = product.variants[product.variants.findIndex( (v) => v.id === variant_id)];
                title += " (";
                title_en += " (";
                selectedVariant.attributes.forEach( (a) => {
                  title += `${a.attribute_category}: ${a.value} `;
                  title_en += `${a.attribute_category_en}: ${a.value} `;
                });
                title += " )";
                title_en += " )";
              }
            }
            //if(not_variant_missmatch){p.discount == product.discount && p.discount_from ===  product.discount_from
              this.products.push({
                ...product,
                name: title,
                name_en: title_en,
                thumbnail: this.getImage(product, selectedVariant),
                selectedVariant: selectedVariant,
                variant_id: variant_id,
                vat_percent: product.vat_percent
              });
              this.calcBasketPicking()
            //}
          })

          if (this.products.length > 0) {
            const tempLocal = this.products.map(a => {
              return {
                id: a.id,
                variant_id: a.variant_id,
                quantity: a.quantity,
                selectedVariant: a.selectedVariant,
                vat_percent: a.vat_percent
              }
            })
            localStorage.setItem('basket', JSON.stringify(tempLocal));
          } else {
            localStorage.setItem('basket', '[]');
          }
          this.onChangeFns.forEach((fn) => fn());
          this.onLoadFns.forEach((fn) => fn());
        })
      } else {
        this.onLoadFns.forEach((fn) => fn());
      }
    })
  }

  removeInvalidProducts() {
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    
    
    this.invalidProducts.forEach(product => {
      const localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id === product.variant_id);
      if(localstorageIndex == -1) return;

      localstorageProducts.splice(localstorageIndex, 1);
    })
    
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));
    this.invalidProducts = [];

    this.onChangeFns.forEach(fn => fn());
  }

  calcBasketPicking() {
    this.products.map( (p) => {
      p.picking_charge = this.getPicking(p, p.quantity)
    })
  }

  // getColorQty(product){
  //   let variant_id = product.selectedVariant.id;
  //   let color = product.variants.filter( (v) => {return v.id == variant_id} )[0].attributes.filter( (a) => {return a.attribute_category_id == 1})[0].value_id;
  //   let products = this.products.filter( (p) => {
  //     return p.id == product.id && 
  //     p.selectedVariant.attributes.filter( (a) => {
  //         return a.attribute_category_id == 1 && a.value_id == color
  //     } ).length > 0
  //     && p.selectedVariant.id != variant_id
  //     ;
  //   });

  //   let qty = product.quantity;

  //   if(products){
  //     products.forEach( (p) => { qty += parseInt(p.quantity)});
  //   }
  //   return qty;
  // }



  getPicking(product, qty){
    let price = 0;
    if(product.picking_package && product.picking_package > 0 && product.picking_price && product.picking_price > 0 ){
      price = ((qty % product.picking_package) * product.picking_price).toFixed(2)
    }

    return price
  }

  addProduct(product, quantity) {
    
    let variant_id = 0;
    if (product.selectedVariant) {
      variant_id = product.selectedVariant.id;
    }
    const productIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    let title = product.name;
    let title_en = product.name_en;
    if (productIndex > -1) {
      this.products[productIndex].quantity = quantity;
      this.products[productIndex].picking_charge = this.getPicking(product, quantity);
      this.products[productIndex].selectedVariant = product.selectedVariant ? product.selectedVariant : false;
      this.products[productIndex].thumbnail = this.getImage(product, product.selectedVariant);
      this.products[productIndex].vat_percent = product.vat_percent;
      if(product.selectedVariant){
        title += " (";
        title_en += " (";
        product.selectedVariant.attributes.forEach( (a) => {
          title += `${a.attribute_category}: ${a.value} `;
          title_en += `${a.attribute_category_en}: ${a.value} `;
        });
        title += " )";
        title_en += " )";
      }
      this.products[productIndex].name = title;
      this.products[productIndex].name_en = title_en;
    } else {
      
      if(product.selectedVariant){
        title += " (";
        title_en += " (";
        product.selectedVariant.attributes.forEach( (a) => {
          title += `${a.attribute_category}: ${a.value} `;
          title_en += `${a.attribute_category_en}: ${a.value} `;
        });
        title += " )";
        title_en += " )";
      }
      this.products.push({
        ...product,
        name: title,
        name_en: title_en,
        variant_id: variant_id,
        selectedVariant: product.selectedVariant ? product.selectedVariant : false,
        thumbnail: this.getImage(product, product.selectedVariant), 
        quantity: quantity,
        picking_charge: this.getPicking(product, quantity),
        vat_percent: product.vat_percent
      });
    }
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = [];
    }
    let localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    if (localstorageIndex > -1) {
      localstorageProducts[localstorageIndex].quantity = quantity;
    }else{
      localstorageProducts.push({
        id: product.id,
        variant_id: variant_id,
        quantity: quantity,
      });
    }

    localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id == variant_id);

    localstorageProducts[localstorageIndex].selectedVariant = product.selectedVariant;
    localstorageProducts[localstorageIndex].vat_percent = product.vat_percent;
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.run_change();

    this.calcBasketPicking()

    discountBasket.filterDependencies(product, this.getFinalQty(product))
  }

  setQuantity(product, quantity) {

    let variant_id = 0;
    if(product.selectedVariant){
      variant_id = product.selectedVariant.id;
    }
    const productIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    if (productIndex > -1) {
      this.products[productIndex].quantity = quantity;
    }
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    const localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id === variant_id);
    if (localstorageIndex > -1)
    localstorageProducts[localstorageIndex].quantity = quantity;
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.run_change();
  }

  removeProduct(product, variant_id) {
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    
    const localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id === variant_id);
    if(localstorageIndex == -1) return;
    let productsIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id === variant_id);
    if (productsIndex > -1) {
      this.products.splice(productsIndex, 1);
    } 
    

    localstorageProducts.splice(localstorageIndex, 1);
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onRemoveProductFns.forEach((fn) => {
      fn(product, variant_id);
    });
    this.run_change();

    discountBasket.filterDependencies(product, this.getFinalQty(product))

    this.calcBasketPicking()
  }

  getFinalQty(product){
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return 0;
    }
    
    const filtered_prods = localstorageProducts.filter((p) => p.id === product.id).map(a => a.quantity);
    if(filtered_prods && filtered_prods.length > 0){
      if(filtered_prods.length == 1) return filtered_prods[0]
      const qty = filtered_prods.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      return qty;
    }else{
      return 0;
    }
  }

  getImage(product, variant){
    let url = product.thumbnail;
    if(variant && product.colors){
      let sel = variant.attributes.filter( (a) => {return a.attribute_category_id == 1});
      if(sel.length > 0){
        let color = product.colors.filter( (v) => {
          return v.value == sel[0].value_id
        
        } )[0];
        if(typeof color !== "undefined"){
          url = color.url;
        }
      }
    }
    return url;
  }

  onAddProduct(fn) {
    this.onAddProductFns.push(fn);
  }

  onRemoveProduct(fn) {
    this.onRemoveProductsFns.push(fn);
  }

  onChange(fn) {
    this.onChangeFns.push(fn);
    return () => {
      this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
    };
  }

  onLoad(fn) {
    this.onLoadFns.push(fn);
    return () => {
      this.onLoadFns.splice(this.onLoadFns.indexOf(fn), 1);
    };
  }

  clear() {
    this.products = [];
    localStorage.setItem('basket', '[]');
    this.run_change();
  }

  clear_object() {
    this.products = [];
    
    this.run_change();
  }

  run_change() {
    request.post('api/save_basket', {
      basket: JSON.parse(localStorage.getItem('basket'))
    })
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }
}

const basket = new Basket();
window.basket = basket;

export default basket;