import gtag_evt from "./gtag";

const favouritesHelper = {
  favourites: JSON.parse(localStorage.getItem('favourites') || '[]'),

  onChangeFns: [],
  onChange(fn) {
    this.onChangeFns.push(fn);
  },
  
  handleOnChangeEvent() {
    localStorage.setItem('favourites', JSON.stringify(this.favourites));
    this.onChangeFns.forEach((fn) => fn());
  },

  add(product) {
    const index = this.favourites.findIndex((p) => p.id === product.id);
    const alreadyAdded = index > -1;
    if (!alreadyAdded) {
      this.favourites.push(product);
      const item_price = product.prices && product.prices.length > 0 ? product.prices[0].price : 0;
      gtag_evt.gtag('event', 'add_to_wishlist', {
        currency: 'EUR',
        items: [{
          item_id: product.code,
          item_name: product.name,
          item_brand: product.manufacturer,
          item_category: null,
          price: item_price,
          currency: 'EUR'
        }],
        value: item_price
      })
    } else {
      this.favourites.splice(index, 1);
    }
    this.handleOnChangeEvent();

    return !alreadyAdded;
  }
};

window.favouritesHelper = favouritesHelper;

export default favouritesHelper;