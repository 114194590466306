import React from 'react';
import i18n from '../i18n';
import './CartAccordion.css'; // Import the CSS file for the component

class CartAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndices: Object.keys(this.props.groupedProducts).map(((key, index) => index))
    };
    this.toggleActiveIndex = this.toggleActiveIndex.bind(this);
  }

    toggleActiveIndex = (index) => {
      this.setState((prevState) => {
        const activeIndices = prevState.activeIndices.includes(index)
          ? prevState.activeIndices.filter((i) => i !== index)
          : [...prevState.activeIndices, index];
  
        return { activeIndices };
      });
    };
    

    render() {
      const { groupedProducts } = this.props;
      const { activeIndices } = this.state;
      this.toggleActiveIndex = this.toggleActiveIndex.bind(this);

      console.log(groupedProducts)

      return (
        <div className="cart-accordion">
          {Object.keys(groupedProducts).map((category, index) => (
            <div key={category} className="accordion-item">
              <div className={`accordion-title ${activeIndices && activeIndices.includes(index) ? "active" : ""}`} onClick={() => this.toggleActiveIndex(index)}>
                {category} ({groupedProducts[category].length}) - {groupedProducts[category].reduce((acc, product) => parseFloat(acc + parseFloat(product.prices[0].price) * parseInt(product.quantity), 0),0).toFixed(2)}€
              </div>
              {activeIndices && activeIndices.includes(index) && (
                <div className={`accordion-content ${activeIndices && activeIndices.includes(index) ? "active" : ""}`}>
                          {groupedProducts[category]
          // .sort((a, b) => {
          //   const totalCostA = parseFloat(a.prices[0].price) * parseInt(a.quantity);
          //   const totalCostB = parseFloat(b.prices[0].price) * parseInt(b.quantity);
          //   return totalCostB-totalCostA;
          // })
          .map((product, i) => (
                    <div key={i} className={`item ${product.status && product.status != '1' ? 'disabled' : ''}`}>
                      <div className="image">
                        <img src={product.thumbnail} alt={i18n.get_name(product)} />
                      </div>
                      <div className="description">
                        <span className="code">{product.code}</span>
                        <span className="title">{i18n.get_name(product)}</span>
                      </div>

                      <span className="price pb-2">
                        <span>{product.quantity} {i18n.t("qty_metric")}</span> ({parseFloat(this.props.calculatePrice(product)).toFixed(2)}&nbsp;€/{i18n.t("qty_metric_single")})
                      </span>
                      {
                        !this.props.hide_changes ? 
                      
                      <div className="details-pr">
                        <div className="product-quantities" style={{ justifyContent: "center" }}>
                          <div className="decrease-btn">
                            <button className="minus-step" onClick={() => this.props.changeQty(product, -1)}>
                              -
                            </button>
                          </div>
                          <input
                            className=""
                            type="number"
                            min="1"
                            onChange={() => {}}
                            value={product.quantity}
                          ></input>
                          <div className="increase-btn">
                            <button className="plus-step" onClick={() => this.props.changeQty(product, +1)}>
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      : ''}
                      {/* <div className="price">{this.props.calculatePrice(product)} €</div> */}
                      <div className="price">{parseFloat(this.props.calculatePrice(product) * product.quantity).toFixed(2)} €
                      </div>

                      <div className="buttons">
                      {
                        !this.props.hide_changes && this.props.removeProduct ? 
                        <span className="delete-btn" onClick={() => this.props.removeProduct(product)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0065bd" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg></span>
                      : ''}
                       </div>

                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  }
  
  export default CartAccordion;