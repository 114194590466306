import './PageHeader.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { useParams, } from 'react-router-dom';
import i18n from '../i18n';
import {
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import storage from '../storage';

function PageHeader(props) {
  let params = useParams();

  let [title, setTitle] = useState('');
  let [category, setCategory] = useState(null);
  let [product, setProduct] = useState(null);
  const [categories, setCategories] = useState(null);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    return () => {
      unlistenOnLanguageChange();
    };
  }, []);

  function getCategoryPath(id) {
    const tree = storage.categories_tree;
  }

  useEffect(() => {
    //if(title != '') return;
    if ('productId' in params) {
      request.get(`api/product-categories/${params.productId}`, false).then((response) => {
        if(response.data.main_category){
          setCategories(response.data.main_category.categories_path);
          setProduct(response.data.main_category);
        }
        
      });
    }

    if ('categoryId' in params) {
      request.get(`api/category-path/${encodeURI(params.categoryId)}`, false).then((response) => {
        if (response.data) {
          setCategories(response.data);
        }
      });
    }

    if (!('productId' in params) && !('categoryId' in params)) {
      setTitle('')
    }
  }, [params.productId, params.categoryId])

  // useEffect(() => {

  // }, [params.productId, params.categoryId]);

  return (
    <div className="page-header">
      <div className='container'>
        <div className='row'>
          <div className="breadcrumb">
            <Link to="/">{i18n.t('home_page')}</Link>
            
            {
              props.page
                ? <React.Fragment>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                  />
                  <span>{i18n.t(props.page + '_on_header')}</span>
                  </React.Fragment>
                : ''
            }
            {
              categories && categories.length &&
                categories.map(c => {
                  return <div className='category-elem'>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                    />
                    <span>
                      <Link key={c.id} to={`/categories/${c.id}`}>{i18n.get_name(c)}</Link>
                    </span>
                  
                </div>
                })
                  
            }
            {
              product &&
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faChevronRight}
                />
                <span>{i18n.get_title(product)}</span>
              </React.Fragment>
            }
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;