import './HomeServices.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";

import i18n from '../i18n';
import config from '../config';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)

class HomeServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        elements: props.services
    };
  }

  componentDidMount() {
    
  }

  

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  render() {
        return (
            <div className="container py-2">
                <div className='row'>
                    <div className='services-bg'>
                        <div className='services-wrapper pt-4'>
                            {
                                this.state.elements.map((e, i) => {
                                    return <div key={`hs-${i}`} className='services-item'>
                                        <FontAwesomeIcon className='main-icon' icon={e.icon}  />
                                        <h6>{i18n.get_title(e)}</h6>
                                        <div className='services-details'>
                                            <p>{i18n.get_desc(e)}</p>
                                        </div>
                                        <div className='services-more'>
                                            <Link style={{textDecoration: 'none'}} to={`/about-us/${e.url}`}>{i18n.t("more")}</Link>
                                        </div>
                
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default HomeServices;