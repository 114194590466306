import './SkeletonProductItem.css';
import React from 'react';

class SkeletonProductItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`skeleton-product-item ${this.props.type}`}>
        <div className="image"></div>
        <div className="info">
          <span className="name"></span>
          <div className="price"></div>
        </div>
      </div>
    );
  }
}

export default SkeletonProductItem;