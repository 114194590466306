import './SkeletonCategoryItem.css';
import React from 'react';

class SkeletonCategoryItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="skeleton-category-item">
        <div className="image"></div>
        <div className="info">
          <span className="name"></span>
          <span className="product-count"></span>
        </div>
      </div>
    );
  }
}

export default SkeletonCategoryItem;