import { createBrowserHistory, } from 'history';

const history = createBrowserHistory({
  
});


history.listen((e) => {
  window.scroll(0, 0);
  history.last = e.pathname;
});

export default history;