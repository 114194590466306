 //const baseURL = "http://localhost/";
//const baseURLadmin = "http://manager.kremmydas.gr/";
const baseURL = "https://kremmydas.gr/";
const baseURLadmin = "https://manager.kremmydas.gr/";

const config = {
  baseURL: baseURL,
  imagesBaseURL: (baseURL !== "") ? baseURLadmin + '/images/' : 'http://manager.kremmydas.gr/images/',
  imagesDevBaseURL: 'http://localhost/admin/images/',
  eshop_title: 'Deal Markets',
  //enviroment: 'development',
  enviroment: 'production'
};

export default config;