import './RangeInput.css';
import React from 'react';

class InputRange extends React.Component {
  constructor(props) {
    super(props);

    this.mouseDown = false;

    this.elementRef = React.createRef();
    this.thumbFromRef = React.createRef();
    this.thumbToRef = React.createRef();

    this.onThumbMouseDown = this.onThumbMouseDown.bind(this);

    this.side = '';
    this.fromPerc = 1;
    this.toPerc = 100;
  }

  componentDidMount() {
    this.onWindowMouseMove = (e) => {
      if (this.mouseDown) {
        let x = e.x - this.elementRef.current.offsetLeft;
        
        if (x < 0) {
          x = 0;
        }

        let valuePerc = parseInt((x / this.elementRef.current.offsetWidth) * 100);
        if (valuePerc >= 0 && valuePerc <= 100 && ((this.side === 'from' && valuePerc < this.toPerc) || (this.side === 'to' && valuePerc > this.fromPerc))) {
          if (this.side === 'from') {
            this.thumbFromRef.current.style.left = x - 8 + 'px';
            this.fromPerc = valuePerc;
            this.props.onChange(this.side, valuePerc);
          }
          if (this.side === 'to') {
            this.thumbToRef.current.style.left = x - 8 + 'px';
            this.toPerc = valuePerc;
            this.props.onChange(this.side, valuePerc);
          }
        }
      }
    };

    this.onWindowMouseUp = (e) => {
      this.mouseDown = false;
    };

    window.addEventListener('mousemove', this.onWindowMouseMove);
    window.addEventListener('mouseup', this.onWindowMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onWindowMouseMove);
    window.removeEventListener('mouseup', this.onWindowMouseUp);
  }

  componentDidUpdate() {
    if (this.props.from >= 0 && this.props.from <= 100) {
      this.fromPerc = this.props.from;
      let x = (this.fromPerc / 100) * this.elementRef.current.offsetWidth;
      this.thumbFromRef.current.style.left = x - 8 + 'px';
    }

    if (this.props.to >= 0 && this.props.to <= 100) {
      this.toPerc = this.props.to;
      let x = (this.toPerc / 100) * this.elementRef.current.offsetWidth;
      this.thumbToRef.current.style.left = x - 8 + 'px';
    }
  }

  onThumbMouseDown(e, side) {
    this.mouseDown = true;
    e.preventDefault();
    this.side = side;
  }

  render() {
    return (
      <div
        className="input-range"
        ref={this.elementRef}
      >
        <div
          className="thumb-from"
          ref={this.thumbFromRef}
          onMouseDown={(e) => this.onThumbMouseDown(e, 'from')}
        ></div>
        <div className="bar"></div>
        <div
          className="thumb-to"
          ref={this.thumbToRef}
          onMouseDown={(e) => this.onThumbMouseDown(e, 'to')}
        ></div>
      </div>
    );
  }
}

export default InputRange;