import '../search-window/SearchHeader.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";
import i18n from '../i18n';
import config from '../config';

class HomeBanners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        const banners = this.props.banners
        return (
            <div className='container py-4'>
                <div className='row'>
                    <div className='cat-wrapper banners'>
                        {
                        banners[0] ? 
                        <div className='banner banner-medium-item' style={{backgroundImage: `url(${config.imagesBaseURL + '/slider/' + banners[0].image})`}}>
                            <Link to={banners[0].action_url}>
                                <div className='itm-title'>
                                    <span>{banners[0].action_text}</span>
                                    <h2>{banners[0].big_header}</h2>
                                    <p>{banners[0].small_header}</p>
                                </div>
                            </Link>
                        </div> : ''
                        }
                        {
                        banners[1] ? 
                        <div className='banner banner-small-item' style={{backgroundImage: `url(${config.imagesBaseURL + '/slider/' + banners[1].image})`}}>
                            <Link to={banners[1].action_url}>
                            <div className='itm-title'>
                                    <span>{banners[1].action_text}</span>
                                    <h2>{banners[1].big_header}</h2>
                                    <p>{banners[1].small_header}</p>
                                </div>
                            </Link>
                        </div>
                        : ''
                        }
                        {
                        banners[2] ? 
                        <div className='banner banner-xs-item' style={{backgroundImage: `url(${config.imagesBaseURL + '/slider/' + banners[2].image})`}}>
                            <Link to={banners[2].action_url}>
                            <div className='itm-title'>
                                    <span>{banners[2].action_text}</span>
                                    <h2>{banners[2].big_header}</h2>
                                    <p>{banners[2].small_header}</p>
                                </div>
                            </Link>
                        </div>
                        : ''
                        }
{
    banners[3] ? 
    <div className='banner banner-big-item' style={{backgroundImage: `url(${config.imagesBaseURL + '/slider/' + banners[3].image})`}}>
        <a href={banners[3].action_url} target="_blank" rel="noopener noreferrer">
            <div className='itm-title'>
                <span>{banners[3].action_text}</span>
                <h2>{banners[3].big_header}</h2>
                <p>{banners[3].small_header}</p>
            </div>
        </a>
    </div>
    : ''
}

                    </div>
                </div>
            </div>
        )
    }

}
export default HomeBanners;