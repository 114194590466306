import './SearchHeader.css';
import React from 'react';
import {
    Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'
import request from '../request';
import history from '../history';
import storage from '../storage';
import config from '../config';
import i18n from '../i18n';

class SearchHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            suggestions: [],
            form_data: {
                test: 1,
                test2: 2
            },
            open: false
            
        };

        this.searchTimeout = null;

        this.elementRef = React.createRef();
        
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, true);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, true);
      }
    
      handleClickInside = () => {
        // Perform actions for click inside the component
        // For example:
        this.setState({
            open: true
        });
      };
    
      handleClickOutside = () => {
        // Perform actions for click outside the component
        // For example:
        this.setState({
            open: false
        });
      };

      handleClick = (event) => {
        if (this.elementRef.current && this.elementRef.current.contains(event.target)) {
          // Handle the click inside logic
          this.handleClickInside();
        } else {
          // Handle the click outside logic
          this.handleClickOutside();
        }
      };
    


    searchProduct = (e) => { //arrow function this ennoei to parent scope
        const query = e.target.value;
        this.setState({
            query:query,
        })
        // this.setState({
            
        //     form_data: {
        //         ...this.state.form_data,
        //         test2: 3
        //     },
        // })

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout( () => {
            request.get(`api/search/products?q=${query}&lite=1`).then((response) => {
                if(response.status === 200) {
                    this.setState({
                        suggestions: response.data.data.products
                    })
                } 
                // console.log(response);
            })
        }, 350)
        
    }

    onQueryKeyDown = (e) => {
        if (e.code === 'Enter') {
          history.push(`/search/products/${this.state.query}`);
        }
      }
      
    render() {
        // console.log(this.state.suggestions);
        return (
            <div className='searchproducts' ref={this.elementRef}>
                <div className={`search-bar ${this.state.query.trim().length > 0 && this.state.suggestions.length > 0 ? 'with-suggestions' : ''}`}>
                  
                    <input 
                    id='header-search'
                    type='text'
                    placeholder={i18n.t('search')}
                    value={this.state.query}
                    onChange={this.searchProduct}
                    onKeyDown={this.onQueryKeyDown}
                    autoComplete="off"
                    autoCorrect='off'
                    />
                    <FontAwesomeIcon icon={faSearch} />
                    <div className={`suggestions ${this.state.query.trim().length > 0 && this.state.suggestions.length > 0 && this.state.open ? 'visible' : ''}`}>
                        <div className='list'>
                            {
                                // console.log(suggestions);
                                this.state.suggestions.map((product, index) => 
                                    <Link
                                      key={index}
                                      className='item'
                                      to={`/products/${product.id}`}
                                    >
                                      <img src={product.thumbnail ? product.thumbnail : '/no-product-image-image.png'} width='60' height='60' style={{objectFit: "contain", marginRight: "5px"}}/>
                                      <b>{product.code}</b>&nbsp; {product.title} 
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SearchHeader;