import './ResetPasswordPage.css';
import React from 'react';
import {
  Link, useParams 
} from 'react-router-dom';
import i18n from '../i18n';
import request from '../request';
import history from '../history';

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams()
    return <Component {...props} params={params} />
  }
  return ComponentWithRouter
}

class ResetPasswordPage extends React.Component {
  constructor(props){
    super(props);    

    
    this.state = {
      password_changed: false,
      error_message: ''
    }; 
  }
  
  componentDidMount() {
    document.title = i18n.t("reset_password_page_title");
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    request.get('api/status').then((response) => {
      if (response.data.login) {
        
      }else{
        history.push('/')
      }
    });
  }

  componentDidUpdate() {
    document.title = i18n.t("reset_password_page_title");
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  submitPasswordChange() {
    const pass = this.state.password
    const confirm_pass = this.state.confirm_password

    if(pass.length > 6 && pass === confirm_pass){
      request.post(`api/reset_pass`, {
        new_password: pass,
        confirm_password: confirm_pass
      }).then(response => {
        if(response.data.status) {
          this.setState({
            ...this.state,
            password_changed: true,
            error_message: ''
          })
        } else {
          if(response.data.message == 'Something went wrong'){
            this.setState({
              ...this.state,
              password_changed: false,
              error_message: i18n.t("something_went_wrong_try_later")
            })
          }else{
            this.setState({
              ...this.state,
              password_changed: false,
              error_message: i18n.t("password_missmatch")
            })
          }
        }
        
      })
    }else if(pass.length <= 6){
      this.setState({
        ...this.state,
        password_changed: false,
        error_message: i18n.t("password_too_small")
      })
    }else if(pass != confirm_pass){
      this.setState({
        ...this.state,
        password_changed: false,
        error_message: i18n.t("password_missmatch")
      })
    }else{
      this.setState({
        ...this.state,
        password_changed: false,
        error_message: i18n.t("something_went_wrong_try_later")
      })
    }
  }

  render() {
    return (
      <div>
          <div
            className="signin-page"
          >
            {
              this.state.password_changed ?
              <div className="form">
                <div className="header">
                  <span style={{background: '#caf5af', padding: '10px', display: 'block'}}>{i18n.t('restore_password_changed')}</span>
                </div>
                <div className="fields">
                  <Link to="/signin" className='login-after-change'>{i18n.t("login")}</Link>
                </div>
              </div> :
              <div className="form">
                <div className="header">
                  {i18n.t('reset_password_on_header')}
                </div>
                {
                this.state.error_message && this.state.error_message != '' ?
                <span className='error-msg'>{this.state.error_message}</span> : ''
                }
                <div className="fields">
                
                  <div className="input">
                    <span>{i18n.t('new_password')}</span>
                    <input 
                      type="password"
                      value={this.state.password}
                      autoComplete="new-password"
                      onChange={(e) => this.setState({...this.state, password: e.target.value})}
                    />
                  </div>
                  <div className="input">
                    <span>{i18n.t('confirm_password')}</span>
                    <input 
                      type="password"
                      autoComplete="new-password"
                      value={this.state.confirm_password}
                      onChange={(e) => this.setState({...this.state, confirm_password: e.target.value})}
                    />
                  </div>

                </div>
                <div className="controls">
                  <button
                    onClick={() => this.submitPasswordChange()}
                    type="button"
                    className="btn-signin"
                  >
                    {i18n.t('restore_password_button')}
                  </button>
                  
                  
                </div>
                
              </div>
            }
            
          </div>
        
      </div>
    );
  }
}
const resetPasswordPage = withRouter(ResetPasswordPage);

export default ResetPasswordPage;