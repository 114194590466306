
import bankersRounding from 'bankers-rounding';

class MathUtils {
  constructor() {
    
  }

  

  round(n, d = 2) {
    //return +(Math.round(num + "e+" + dec)  + "e-" + dec);
    //return Math.round( ( num + Number.EPSILON ) * 100 ) / 100
    // var num_sign = num >= 0 ? 1 : -1;
    // return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
    var x = n * Math.pow(10, d);
    var r = Math.round(x);
    var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r-1) : r;
    return (br / Math.pow(10, d));

    //return bankersRounding(n, d);

  }
}

const mathUtils = new MathUtils();

export default mathUtils;