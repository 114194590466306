import './BrochuresPage.css';
import React from 'react';
import request from '../request';
import BrochureItem from './BrochureItem';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faAngleLeft, faAngleRight, faFilter, faSort,faThList, faBars } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import history from '../history';

class BrochuresPage extends React.Component {
  constructor(props) {
    super(props);



    this.state = {
      brochures: [],
      fetchingbrochures: false,
    };

    this.elementRef = React.createRef();

    this.getbrochures = this.getbrochures.bind(this);
  }

  componentDidMount() {
    this.getbrochures();
    
    document.title = i18n.t("brochures_page_title");
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      fetchingbrochures: true,
    });

}

  componentDidUpdate(prevProps, prevState) {
    
    document.title = i18n.t("brochures_page_title");
    if (prevState.fetchingbrochures !== this.state.fetchingbrochures) {
      this.setState({
        ...this.state,
        fetchIsDirty: true,
      });
    }
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  getbrochures(isLite = false) {
    if (!history.location.pathname.includes('brochures')) { // page changed FIXME
      return;
    }
    
    this.setState({
      ...this.state,
      fetchingbrochures: true,
    });


    request.get("api/prospectuses").then((response) => {

      if(!request.response_valid(response)){
        return;
      }
      
      if (response.data.length > 0) {
        this.setState({
          ...this.state,
          total: response.data.total,
          brochures: response.data.map((_brochure) => {
            return {
              id: _brochure.id,
              name: _brochure.title,
              thumbnail: _brochure.thumbnail,
            };
          }),
          fetchingbrochures: false,
        });
      }
    });
  }
  
  render() {
    return (
    <div className="container py-4 brochurespage">
      <div
        className="brochures-page"
        ref={this.elementRef}
      >
        <div className='filters-wrapper'>
          <div className="options">
            <div className="d-flex">
              <div className='filter--toggle'>
                <button
                  type="button"
                  className={`btn ${this.state.showFilters === false ? 'btn-outline' : ''} ml-1`}
                  onClick={() => this.setState({ ...this.state, showFilters: !this.state.showFilters, })}
                >
                  <FontAwesomeIcon icon={faFilter} />
                  &nbsp;
                  {i18n.t('filters')}
                </button>
              </div>
              
              
            </div>
          </div>
          <div className={`mobile-fixed-filters filters ${this.state.viewType} ${this.state.showFilters ? '' : 'mobile-hidden'} p-1`} >
            <div className='mobile-attributes-backdrop' onClick={() => {
              this.setState({
                ...this.state,
                showFilters: false
              })
            }}></div>

          </div>
        </div>
        <div className='brochures-wrapper'>

          <h3 className="title title-padding">{i18n.t('brochures_navigation')}</h3>
            <div className='d-flex options'>
              <div className='have-found'>
                <span>
                  {i18n.t('have_found')} { this.state.total } {i18n.t('have_found3')}
                </span>
              </div>

            </div>

          <div className={`_brochure-items-wrapper d-flex ${this.state.viewType === 'grid' ? 'flex-direction-column' : 'flex-direction-row'}`}>
            
            <div className={`list mt-5`}>
              {
                this.state.fetchingbrochures === false && this.state.brochures && this.state.brochures.map((_brochure, i) => {
                  let BrochureComponent;

                    BrochureComponent = <BrochureItem
                      key={i}
                      data={_brochure}
                    />;
                  
                  return BrochureComponent;
                })
              }

              {
                this.state.fetchingbrochures === false && this.state.brochures &&  this.state.brochures.length === 0 && this.state.fetchIsDirty && <div
                  className="no-results-message"
                > 
                  <FontAwesomeIcon icon={faBoxOpen} />
                  <span>{i18n.t('no_brochures_found')}</span>
                </div>
              }
            </div>

          </div>


        </div>
        
      </div>
    </div>
    );
  }
}

export default BrochuresPage;