import './ComingSoonItem.css';
import React from 'react';
import i18n from '../i18n';
import {
  Link,
} from 'react-router-dom';

class ComingSoonItem extends React.Component {
  constructor(props) {
    super(props);

      this.state = {
        thumbnail: null
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    
    const image = new Image();
    image.onload = () => {
      this.setState({
        ...this.state,
        thumbnail: this.props.data.thumbnail,
      });
    };

    image.onerror = () => {
      this.setState({
        ...this.state,
        thumbnail: '/no-product-image-image.png',
      });
    };

    image.src = this.props.data.thumbnail;
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange()
  }
  
  render() {
    return (
      <div className="product-item"> 
        <div
          className="image-wrapper"
        >
         
          <Link
            to={`/article/${this.props.data.id}`}
          >
            <img src={this.state.thumbnail} />
            
          </Link>
          
        </div>
        <div className="info">
          <Link
            to={`/article/${this.props.data.id}`}
            className="name"
          >
            {i18n.get_title(this.props.data)}
          </Link>
        </div>
      </div>
    );
  }
}

export default ComingSoonItem;