import React, { useEffect, useState } from 'react';
import styles from './ProductCard.module.css';
import request from '../request';
import i18n from '../i18n';
import basket from '../basket';

function ProductCard({ product }) {
  const [productData, setProductData] = useState({});
  const [selected_quantity, setSelectedQuantity] = useState();
  const [current_pckg, setCurrent_pckg] = useState(1);

  useEffect(() => {
    request.get(`api/product/${product.id}`).then((response) => {
      if (!request.response_valid(response)) {
        return;
      }

      setProductData(response.data);
      setSelectedQuantity(response.data.package_qty)
    });
  }, [product.id]);

  useEffect(() => {
    console.log(productData);
  }, [productData]);

  const changeQty = (qty) => {
    const { product } = productData;

    const current_qty =
      parseInt(product.selected_quantity) + qty * product.package_qty;
    const current_price = onPriceSetChange(
      current_qty > 0 ? current_qty : product.package_qty
    );
    const current_pckg = parseInt(product.current_pckg) + qty;

    this.setState({
      ...this.state,
      product: {
        ...product,
        selected_quantity: current_qty > 0 ? current_qty : product.package_qty,
        current_price: current_price
          ? current_price.price || product.current_price
          : product.current_price,
        current_pckg: current_pckg > 0 ? current_pckg : 1,
      },
    });
  };

  const addToBasket = () => {
    const { product } = productData;
    basket.addProduct(product, product.selected_quantity);
    this.setState({
      ...this.state,
      showToast: true,
      in_basket: basket.products.findIndex((i) => i.id === product.id) > -1,
    });
  };

  const onPriceSetChange = (qty) => {
    const { product } = this.state;

    if (product.prices && product.prices.length > 0) {
      let current_price = null;
      let valid_prices = product.prices.filter((p) => {
        return parseInt(p.qty) <= qty;
      });

      if (valid_prices.length > 0) {
        current_price = valid_prices[0];
        let single_price = valid_prices.reduce(function (p, v) {
          return parseInt(p.qty) > parseInt(v.qty) ? p : v;
        });
        current_price = single_price;
      }

      if (
        !product.current_price ||
        (current_price && current_price.price !== product.current_price)
      ) {
        return current_price;
      }
    }
  };  

  return (
    <div className={styles["product"]}>
      <h6>{productData.title}</h6>
      {/*<p>{product.description}</p>*/}
      <img src={productData.thumbnail} alt={productData.title} />

      {productData.prices && productData.prices.length > 0 ? (
            <div className="price py-2">
              <span className={`initial-price`}>
                {i18n.t("from")}{" "}
                {parseFloat(productData.prices[0].price).toFixed(2)}&nbsp;€
              </span>

              <div className="quantities-section pt-4">
                <div className="inn">
                  <div className="details-pr">
                    <label className="fw-bold">{i18n.t("pieces_t")}</label>
                    <div className="product-quantities">
                      <div className="decrease-btn">
                        <button
                          className="minus-step"
                          onClick={() => changeQty(-1)}
                        >
                          -
                        </button>
                      </div>
                      <input
                        className=""
                        type="number"
                        min="1"
                        onChange={() => {}}
                        value={selected_quantity}
                      ></input>
                      <div className="increase-btn">
                        <button
                          className="plus-step"
                          onClick={() => changeQty(+1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="details-pr">
                    <label className="fw-bold">{i18n.t("pieces_p")}</label>
                    <div className="product-quantities">
                      <div className="decrease-btn">
                        <button
                          className="minus-step"
                          onClick={() => changeQty(-1)}
                        >
                          -
                        </button>
                      </div>
                      <input
                        className=""
                        type="number"
                        min="1"
                        onChange={() => {}}
                        value={current_pckg}
                      ></input>
                      <div className="increase-btn">
                        <button
                          className="plus-step"
                          onClick={() => changeQty(+1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="quant-add pt-2">
                  <button
                    className="add-to-basket"
                    onClick={() => addToBasket()}
                  >
                    {i18n.t("add_to_basket")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="price py-2">
              <span className="initial-price">{i18n.t("seeprice")}</span>
            </div>
          )}      

    </div>
  );
}

export default ProductCard;
