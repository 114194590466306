import config from "./config";

class Gtag_events {
	constructor() {
		window.dataLayer = window.dataLayer || [];
		
		this.gtag('js', new Date());

		// this.gtag('config', 'G-XXXXXXXXXX');
	}

	gtag(){
		if (config.enviroment != 'production')
			console.log('tracking init', arguments)
		window.dataLayer.push(arguments);
	}
}

const gtag_evt = new Gtag_events();

export default gtag_evt;