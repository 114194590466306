import './ProductsMiscPage.css';
import React, { Fragment } from 'react';
import {
  Link,
} from "react-router-dom";
import i18n from '../i18n';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import ProductItem from '../product-item/ProductItem';
import request from '../request'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faEye, } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import favouritesHelper from '../favouritesHelper';
import basket from '../basket';

class ProductsMiscPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      featuredProducts: [],
      favouriteProducts: [],
      seenProducts: [],
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    request.get(`api/products/featured`).then((response) => {
      this.setState({
        ...this.state,
        featuredProducts: response.data.data.products.map((product) => {
          return {
            id: product.id,
              name: product.title,
              name_en: product.title_en,
              code: product.code,
              mu_code: product.mu_code,
              description: product.description,
              image: product.thumbnail || product.images[0],
              rating: product.rating,
              price: product.price,
              prices: product.prices,
              availability: product.availability,
              variants: product.variants,
              colors: product.colors,
              manufacturer: product.manufacturer,
              package_qty: product.package_qty,
              vat_percent: product.vat_percent,
              qty: product.package_qty,
              max_qty:  product.max_quantity,
              discount: product.discount ? product.discount : null,
              campain: product.campain ? product.campain : null,
              category: product.category,
              category_id: product.category_id,
              category_name: product.category_name,
              thumbnail: product.thumbnail,
              new_arrival: product.new_arrival,
              is_top: product.is_top,
              qty_step: product.qty_step,
              last_orders: product.last_orders,
          };
        }),
      });
    });

    let lastSeenProducts = localStorage.getItem('last-seen-products');
    if (lastSeenProducts) {
      lastSeenProducts = JSON.parse(lastSeenProducts);
    } else {
      lastSeenProducts = [];
    }

    const fetchLastSeenProductsPromises = [];
    lastSeenProducts.forEach((productId) => {
      fetchLastSeenProductsPromises.push(request.get(`api/product/${productId}`, false).then((response) => {
        return response.data;
      }));
    });

    Promise.all(fetchLastSeenProductsPromises).then((products) => {
      this.setState({
        // ...this.state,
        seenProducts: products.map((product) => {
          return {
            id: product.id,
              name: product.title,
              name_en: product.title_en,
              code: product.code,
              mu_code: product.mu_code,
              description: product.description,
              image: product.thumbnail || product.images[0],
              rating: product.rating,
              price: product.price,
              prices: product.prices,
              availability: product.availability,
              variants: product.variants,
              colors: product.colors,
              manufacturer: product.manufacturer,
              package_qty: product.package_qty,
              vat_percent: product.vat_percent,
              qty: product.package_qty,
              max_qty:  product.max_quantity,
              discount: product.discount ? product.discount : null,
              campain: product.campain ? product.campain : null,
              category: product.category,
              category_id: product.category_id,
              category_name: product.category_name,
              thumbnail: product.thumbnail,
              new_arrival: product.new_arrival,
              is_top: product.is_top,
              qty_step: product.qty_step,
              last_orders: product.last_orders,
          };
        }),
      });
    });

    const favoritesProductsPromises = [];
    favouritesHelper.favourites.forEach((product) => {
      favoritesProductsPromises.push(request.get(`api/product/${product.id}`, false).then((response) => {
        return response.data;
      }));
    });

    Promise.all(favoritesProductsPromises).then((products) => {
      this.setState({
        // ...this.state,
        favouriteProducts: products.map((product) => {
          return {
            id: product.id,
              name: product.title,
              name_en: product.title_en,
              code: product.code,
              mu_code: product.mu_code,
              description: product.description,
              image: product.thumbnail || product.images[0],
              rating: product.rating,
              price: product.price,
              prices: product.prices,
              availability: product.availability,
              variants: product.variants,
              colors: product.colors,
              manufacturer: product.manufacturer,
              package_qty: product.package_qty,
              vat_percent: product.vat_percent,
              qty: product.package_qty,
              max_qty:  product.max_quantity,
              discount: product.discount ? product.discount : null,
              campain: product.campain ? product.campain : null,
              category: product.category,
              category_id: product.category_id,
              category_name: product.category_name,
              thumbnail: product.thumbnail,
              new_arrival: product.new_arrival,
              is_top: product.is_top,
              qty_step: product.qty_step,
              last_orders: product.last_orders,
          };
        }),
      });
    });
    // this.setState({
    //   ...this.state,
    //   favouriteProducts: favouritesHelper.favourites,
    // });

    favouritesHelper.onChange(() => {
      this.setState({
        ...this.state,
        favouriteProducts: favouritesHelper.favourites,
      });
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  addToBasket(product) {
    basket.addProduct(product, 1);
  }

  render() {
    return (
      <div className="products-misc-page">
        <div className="section featured-products column">
          <h3 className="title">{i18n.t('favourite_products_title')}</h3>
          <div className="list">
            {
              this.state.favouriteProducts.length === 0
                ? <div className="empty-list-view">
                    <FontAwesomeIcon icon={faHeart} />
                    <span className="fs-lg mt-5">{i18n.t('no_products_in_list')}</span>
                  </div>
                : ''
            }
            {
              this.state.favouriteProducts.map((product, i) => (
                <ProductItem
                  key={i}
                  data={product}
                  onAddToBasket={() => this.addToBasket(product)}
                />
              ))
            }
          </div>
        </div>
        <div className="section featured-products column">
          <h3 className="title">{i18n.t('seen_products_title')}</h3>
          <div className="list">
            {
              this.state.seenProducts.length === 0
                ? <div className="empty-list-view">
                    <FontAwesomeIcon icon={faEye} />
                    <span className="fs-sm mt-5">{i18n.t('no_products_in_list')}</span>
                  </div>
                : ''
            }
            {
              this.state.seenProducts.map((product, i) => (
                <ProductItem
                  key={i}
                  data={product}
                  onAddToBasket={() => this.addToBasket(product)}
                />
              ))
            }
          </div>
        </div>
        <div className="section featured-products column">
          <h3 className="title">{i18n.t('featured_products_title')}</h3>
          <div className="list">
            {
              this.state.featuredProducts.map((product, i) => {
                return (
                  <ProductItem
                    key={i}
                    data={product}
                    onAddToBasket={() => this.addToBasket(product)}
                  />
                );
              })
            }
            {
              this.state.featuredProducts.length === 0 && Array.from(Array(12)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsMiscPage;