import './CategoriesPage.css';
import React from 'react';
import CategoriesItem from '../categories-item/CategoriesItem';
import ProductItem from '../product-item/ProductItem';
import i18n from '../i18n';
import request from '../request';

class CategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      products: [], //
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    document.title = i18n.t('categories_page_title');

    request.get(`api/categories`, false).then((response) => {
      
      let data = response.data;

      let getCategories = response.data.categories; //
      let getProducts = response.data.products; //

      // data = data.sort((a, b) => {
      //   if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
      //     return -1;
      //   } else {
      //     return 1;
      //   }
      // });
      if(getCategories != undefined) {
        
      }
      else {
        getCategories = data;
      }

      // this.setState({
      //   ...this.state,
      //   categories: data.map((category) => {
      //     return {
      //       id: category.id,
      //       name: category.name,
      //       image: category.image,
      //       productCount: category.productsCount,
      //       parentCategoryId: parseInt(category.parent_id),
      //     };
      //   }),
      // });
      this.setState({
        ...this.state,
        categories: getCategories.map((category) => {
          return {
            id: category.id,
            name: category.name,
            name_en: category.name_en,
            image: category.image,
            fallback_image: category.fallback_image,
            productCount: category.productsCount,
            parentCategoryId: parseInt(category.parent_id),
          };
        }),
      });

      if(getProducts != undefined) {
        this.setState({
          ...this.state,
          products: getProducts.map((product) => {
            return {
              id: product.id,
              code: product.code,
              title: product.title,
              thumbnail: product.thumbnail,
            };
          }),
        });
      }

    });
  }

  componentDidUpdate() {
    document.title = i18n.t('categories_page_title');
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  render() {
    return (
      <div className="categories-page">
        <CategoriesItem categories={this.state.categories} />
        
        <div className='products-list'>
          { //
            this.state.products.map((product, i) => {
              return (
                <ProductItem 
                  key={i}
                  data={product}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default CategoriesPage;