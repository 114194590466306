import './ProductItem.css';
import React from 'react';
import i18n from '../i18n';
import {
  Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartFilled, faEye, faShoppingBasket, faTimes, faStar as faStarFilled, faPaperPlane, faGift, faGifts, faRedo } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import basket from '../basket';
import favouritesHelper from '../favouritesHelper';
import ProductAvailability from '../product-availability/ProductAvailability';
import ProductAttributes from '../product-attributes/ProductAttributes';
import ProductColors from '../product-colors/ProductColors';
import request from '../request';
import Select, { StylesConfig } from 'react-select';

class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    

    this.state = {
      hoveringFavouriteButton: false,
      showToast: false,
      showFavouriteToast: false,
      showErrorToast: false,
      thumbnail: '',
      count: 1,
      qty_selector: 'pieces',
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
      product: {},
      show_older_orders: false
    };

    this.onButtonFavouriteMouseOver = this.onButtonFavouriteMouseOver.bind(this);
    this.onButtonFavouriteMouseLeave = this.onButtonFavouriteMouseLeave.bind(this);
    this.onButtonFavouriteClick = this.onButtonFavouriteClick.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
    this.closeFavouriteToast = this.closeFavouriteToast.bind(this);
    this.closeErrorToast = this.closeErrorToast.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.changeQty = this.changeQty.bind(this);
    this.onPriceSetChange = this.onPriceSetChange.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
  }

  componentDidMount() {
    const product = {
      ...this.props.data,
      //selected_quantity: parseInt((this.props.data.qty_step > 0) ? this.props.data.qty_step : this.props.data.package_qty),
      selected_quantity: parseInt(this.props.data.package_qty),
      current_pckg: 1
    };
    this.setState({ product });

    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    favouritesHelper.onChange(() => {
      this.setState({
        ...this.state,
        isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
      });
    });

    const image = new Image();
    image.onload = () => {
      this.setState({
        ...this.state,
        thumbnail: this.props.data.image,
      });
    };

    image.onerror = () => {
      this.setState({
        ...this.state,
        thumbnail: '/no-product-image-image.png',
      });
    };

    image.src = this.props.data.image;

    this.handleClickOutside = () => {
      this.setState({
        show_older_orders: false
      })
      //setNavMobileOpen
    };

    document.addEventListener('click', this.handleClickOutside, true);
  }


  componentWillUnmount() {
    this.unlistenOnLanguageChange()
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  onButtonFavouriteMouseOver() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: true,
    });
  }

  onButtonFavouriteMouseLeave() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: false,
    });
  }

  onButtonFavouriteClick() {
    const addedAsFavourite = favouritesHelper.add(this.props.data);

    this.setState({
      ...this.state,
      showFavouriteToast: addedAsFavourite,
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        showFavouriteToast: false,
      });

      this.forceUpdate();
    }, 5000);
  }

  

  closeToast() {
    this.setState({
      ...this.state,
      showToast: false,
    });
  }

  onCountChange(value) {
    this.setState({
      ...this.state,
      count: value,
    });
  }

  closeFavouriteToast() {
    this.setState({
      ...this.state,
      showFavouriteToast: false,
    });
  }
  
  closeErrorToast() {
    this.setState({
      ...this.state,
      showErrorToast: false,
    });
  }

  setNewVariantData(v) {
    this.setState({
      ...this.state,
      selectedVariant: v,
    });
  }

  onChangeImage(p) {
    if(p.url){
      this.setState({
        ...this.state,
        thumbnail: p.url,
      });
    }else{
      request.get(`api/product/color_image/${this.props.data.id}/${p.value}`).then( (response) => {
        if(response.status === 200){
          this.setState({
            ...this.state,
            thumbnail: response.data.image,
          });
        }
      })
    }
    
  }

  handleChange = ((event) => {
    this.setState({qty_selector: event.target.value});
  })

  


  handleOnMouseDown (e) {
    this.setState({
      clientXonMouseDown: e.clientX,
      clientYonMouseDown: e.clientY
    })
    e.preventDefault() // stops weird link dragging effect
  }

  handleOnClick (e) {
    e.stopPropagation()
    if (this.state.clientXonMouseDown !== e.clientX || 
        this.state.clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault()
    }
  }

  onPriceSetChange = (qty) => {
    const { product } = this.state;
    
    if (product.prices && product.prices.length > 0) {
      let current_price = null;
      let valid_prices = product.prices.filter((p) => {
        return parseInt(p.qty) <= qty;
      });
      
      if (valid_prices.length > 0) {
        current_price = valid_prices[0];
        let single_price = valid_prices.reduce(function (p, v) {
          return parseInt(p.qty) > parseInt(v.qty) ? p : v;
        });
        current_price = single_price;
      }
      
      if (!product.current_price || (current_price && current_price.price !== product.current_price)) {
        return current_price;
      }
    }
  };

  
  changeQty = (qty, type) => {
    const { product } = this.state;

    const splittable = parseInt(product.qty_step) < parseInt(product.package_qty)
    let current_qty = product.selected_quantity
    let current_pckg = product.current_pckg
    let newcurrent_qty = parseInt(product.selected_quantity + (qty * product.qty_step))

    if (type === 'items') {

      if(newcurrent_qty < product.qty_step) return  
    
      if (splittable && newcurrent_qty <= product.package_qty && current_pckg <= 1){
        current_qty = parseInt(product.selected_quantity) + (qty * parseInt(product.qty_step))
        current_pckg = (parseFloat(current_qty / product.package_qty).toFixed(1) === "1.0") ? 1 : parseFloat(current_qty / product.package_qty).toFixed(1)
      }else{
        current_qty = parseInt(product.selected_quantity) + (qty * parseInt(product.package_qty))
        current_pckg = parseInt(current_pckg + qty)
      }

    } else if (type === 'packages') {

      if (current_pckg < 1 && qty === -1) return;

      current_pckg = (splittable && current_pckg + qty <= 1) ? 1 : parseInt(current_pckg + qty)
      current_qty = (splittable && current_pckg + qty <= 1) ? product.package_qty : product.package_qty * current_pckg
    }

    const current_price = this.onPriceSetChange(current_qty > 0 ? current_qty : parseInt((product.qty_step > 0) ? product.qty_step : product.package_qty));

    this.setState({
      ...this.state,
      product: {
        ...product,
        selected_quantity: current_qty > 0 ? current_qty : parseInt((product.qty_step > 0) ? product.qty_step : product.package_qty),
        current_price: current_price ? current_price.price || product.current_price : product.current_price,
        current_pckg: current_pckg > 0 ? current_pckg : 1
      }
    });
    
  };
  

  addToBasket = () => {
    const { product } = this.state;
    basket.addProduct(product, product.selected_quantity);
    this.setState({
      ...this.state,
      showToast: true,
      in_basket: basket.products.findIndex((i) => i.id === product.id) > -1,
    });
  };
  
  render() {
    const price_obj = this.props.data.prices ? this.props.data.prices[0] : null;

    const final_price_text = ( price_obj && (price_obj.discount_percent_1 > 0 || price_obj.discount_percent_2 > 0)) ? i18n.t('final_price') : i18n.t('price')
    const { selected_quantity, current_pckg } = this.state.product;
    const in_basket = basket.products.findIndex((i) => i.id == this.props.data.id) > -1;
    return (
      <div className={`product-item ${in_basket ? 'in-basket' : ''}`}>
        <div className="product-labels">
          {(this.state.product.new_arrival > "0") && <div className="product-new">{i18n.t('product_new')}</div>}
          {(this.state.product.is_top > "0") && <div className="product-top">{i18n.t('product_top')}</div>}
        </div>
        {/* {
          this.getBrandImage(this.props.data.manufacturer) &&
          <div className='brand-mini-img'>
            <img src={`https://manager.dealmarkets.eu/images/${this.getBrandImage(this.props.data.manufacturer)}`} alt="brand image"/>
          </div>
        } */}

        
        <div
          className="image-wrapper"          
        >
         
          <Link
            to={`/products/${this.props.data.id}`}
            aria-label={i18n.get_name(this.props.data)}
            onMouseDown={e => this.handleOnMouseDown(e)}
            onClick={e => this.handleOnClick(e)}
          >
            <img src={this.state.thumbnail || '/no-product-image-image.png'} alt="Thumbnail" />
            {
              this.props.data.discount &&
              <div className='discount-banner'>
                <span>-{this.props.data.discount.percentage}%</span>
              </div>
            }
            {
              this.props.data.campain &&
              <div className='campain-banner'>
                <span>
                  <FontAwesomeIcon
                    icon={faGifts}
                  />
                </span>
              </div>
            }
          </Link>
          
        </div>
        
        <div
          className="btn-favourite"
          onMouseOver={this.onButtonFavouriteMouseOver}
          onMouseLeave={this.onButtonFavouriteMouseLeave}
          onClick={this.onButtonFavouriteClick}
        >
          {
            this.state.hoveringFavouriteButton || this.state.isFavourite
              ? <FontAwesomeIcon
                  icon={faHeartFilled}
                  size="lg"
                />
              : <FontAwesomeIcon
                  icon={faHeart}
                  size="lg"
                />
          }
        </div>
        
        <div className="info">
          {/* <Link
            to={`/products/${this.props.data.id}`}
            className="name"
          > */}
            <span>{i18n.get_name(this.props.data)}</span>
            <div>
            <span className='infoCode'>{i18n.t('code_product')}: {this.props.data.code}</span>
            {
                this.props.data.prices && this.props.data.prices.length > 0 ?
                <div className="price py-2">
                  {
                    price_obj.discount_percent_1 > 0 ? 
                    <div className={`discount-price discount-price-1`}>
                      <div className='discount-price-element'>{i18n.t('from')} {parseFloat(price_obj.initial_price).toFixed(2)}€ </div>
                      <div className='discount-percent-element'>({parseFloat(price_obj.discount_percent_1).toFixed(0)}% {i18n.t('discount')})</div>
                    </div> : ''
                  }
                  {
                    price_obj.discount_percent_2 > 0 ? 
                    <div className={`discount-price discount-price-1`}>
                      <div className='discount-price-element'>{i18n.t('from')} {parseFloat(price_obj.discount_price_1).toFixed(2)}€ </div>
                      <div className='discount-percent-element'>({parseFloat(price_obj.discount_percent_2).toFixed(0)}% {i18n.t('discount')})</div>
                    </div> : ''
                  }
                  <span className={`initial-price final-price`}>
                    <div className='final-price-text'>
                    {final_price_text}
                    </div>
                    <div className='final-price-num'>{parseFloat(this.props.data.prices[0].price).toFixed(2)}€</div>
                  </span>

              <div className='quantities-section pt-2'>
                <div className='inn'>

          {this.props.data.mu_code === "DISPLAY" ? (

<div className='details-pr'>
<label className='fw-bold'>{i18n.t('pieces_d')}</label>
<div className='product-quantities'>
  <div className='decrease-btn'>
    <button className='minus-step' onClick={() => this.changeQty(-1, "items")}>-</button>
  </div>
  <input className='' type='number' min='1' onChange={() => {}} value={selected_quantity}></input>
  <div className='increase-btn'>
    <button className='plus-step' onClick={() => this.changeQty(+1, "items")}>+</button>
  </div>
</div>
</div>

          ) : (
          <>
          <div className='details-pr'>
            <label className='fw-bold'>{i18n.t('pieces_t')}</label>
            <div className='product-quantities'>
              <div className='decrease-btn'>
                <button className='minus-step' onClick={() => this.changeQty(-1, "items")}>-</button>
              </div>
              <input className='' type='number' min='1' onChange={() => {}} value={selected_quantity}></input>
              <div className='increase-btn'>
                <button className='plus-step' onClick={() => this.changeQty(+1, "items")}>+</button>
              </div>
            </div>
          </div>
          
          <div className='details-pr'>
            <label className='fw-bold'>{i18n.t('pieces_p')}</label>
            <div className='product-quantities'>
              <div className='decrease-btn'>
                <button className='minus-step' onClick={() => this.changeQty(-1, "packages")}>-</button>
              </div>
              <input className='' type='number' min='1' onChange={() => {}} value={current_pckg}></input>
              <div className='increase-btn'>
                <button className='plus-step' onClick={() => this.changeQty(+1, "packages")}>+</button>
              </div>
            </div>
          </div>
          </>
          )
    }
      </div>
                <div className='quant-add pt-2'>
                {
                    this.props.data.last_orders && this.props.data.last_orders.length > 0 ?
                    <div className="older-orders-wrapper mr-1" onMouseEnter={ () => this.setState({show_older_orders: !this.state.show_older_orders})} 
                      onMouseLeave={ () => this.setState({show_older_orders: false})}
                    >

                    
                    
                    <button className="add-to-basket older-orders-innit" ><FontAwesomeIcon icon={faRedo} /> <FontAwesomeIcon icon={faShoppingBasket} /></button> 
                    {(this.props.data.last_orders && this.props.data.last_orders.length > 0) ? (
                  <div className={`details-pr latest-orders latest-orders-item ${this.state.show_older_orders ? '' : ' d-none'}`}>
                      <label className='latest-title'>{i18n.t('latest_orders')}</label>
                        <div className='latest-orders-wrapper'>
                        {this.props.data.last_orders.map((latest_product, i) => {
                        return (
                          <div key={i} className='latest-order-container'>
                            <label htmlFor={`add${i}`} className='latest-order-date'>{`${latest_product.date}`}</label>
                            <button id={`add${i}`} className='latest-order-button' onClick={() => { 
                              this.setState({
                                product: {
                                  ...this.state.product,
                                  selected_quantity: latest_product.qty
                                }
                              }, () => {
                                this.addToBasket()
                              })
                             }}>{`${latest_product.qty}`}&nbsp;
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-basket" class="svg-inline--fa fa-shopping-basket fa-w-18 fa-lg " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-tip="true" data-for="controlsTip-basket" currentItem="false"><path fill="currentColor" d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"></path></svg>
                            </button>
                          </div>
                        )})}
                        </div>                    
                  </div>
            ) : ( null )}
                    </div>
                    : ''
                  }
                  <button className="add-to-basket" onClick={ () => this.addToBasket()}><FontAwesomeIcon icon={faShoppingBasket} /> {i18n.t('add_to_basket')}</button>
                  
                </div>
                
              </div>

                </div>
                : <div className="price py-2">
                  <span className='initial-price'>{i18n.t('seeprice')}</span>
                </div>
                
            } 
            </div>
          {/* </Link> */}
          {
            this.props.children
          }
          
          {/* <div className="color-picker-wrapper">
                <ProductColors data={this.props.data.colors} onChangeImage={ (p) => {this.onChangeImage(p)}}/>
          </div> */}
        </div>
        

        

        

        <div className={`toast toast-error ${this.state.showErrorToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeErrorToast}
          />
          {i18n.t('product_added_error')}
        </div>

        <div className={`toast toast-favourite ${this.state.showFavouriteToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeFavouriteToast}
          />
          {i18n.t('product_added_as_favourite')}
        </div>

        <div className={`toast ${this.state.showToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeToast}
          />
          Το προϊόν προστέθηκε&nbsp;<a href="/basket">στο καλάθι σας</a>
        </div>        

        
      </div>
    );
  }
}

export default ProductItem;