import React from "react";
import {
    Link,
  } from 'react-router-dom';
import i18n from '../i18n';

const findParents = (id, tree, parents = []) => {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === id) {
            return [...parents, id];
        } else if (node.children) {
            const found = findParents(id, node.children, [...parents, node.id]);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

const findCategory = (id, tree) => {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === id) {
            return node;
        } else if (node.children) {
            const found = findCategory(id, node.children);
            if (found) {
                return found;
            }
        }
    }
    return null;
};

const CategoryTree = ({ categoriesTree, categoryId }) => {

    if (!categoriesTree || categoriesTree === "undefined" || categoryId === "undefined") return null;


    categoriesTree = categoriesTree[0].children;

    

    const parents = findParents(categoryId, categoriesTree);
    const currentCategory = findCategory(categoryId, categoriesTree);
    const childrenOfCurrent = currentCategory && currentCategory.children ? currentCategory.children.map(child => child.id) : [];

    const renderCategory = (category, level = 0) => {
        const isCurrentCategory = category.id === categoryId;
        const indentation = "  ".repeat(level);

        // Only render the category if it's a first level category, a parent, the current category, or a child of the current category
        if ((level === 0 || (parents && parents.includes(category.id)) || isCurrentCategory || childrenOfCurrent.includes(category.id)) && (category.productsCount > 0)) {
            return (
                <div key={category.id} className="choice">
                    <span style={{ fontWeight: isCurrentCategory ? "bold" : "normal" }}>
                    <Link to={`/categories/${category.id}`}
                              aria-label={category.name}
                              >
                              <span className='categ-name'>{indentation + i18n.get_name(category)}</span>
                              <span className="product-count">({category.productsCount})</span>
                    </Link>                        
                    </span>
                    {category.children && category.children.length > 0 && (
                        <div style={{ marginLeft: "10px" }}>
                            {category.children.map((child) => renderCategory(child, level + 1))}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            {categoriesTree.map((category) => renderCategory(category))}
        </div>
    );
};

export default CategoryTree;
