import './HomePage.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";
import i18n from '../i18n';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.css';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import ProductItem from '../product-item/ProductItem';
import request from '../request';
import config from '../config';
import basket from '../basket';
import HomeCategories from '../home-categories/HomeCategories';
import HomeServices from '../home-services/HomeServices';
import MiniSlide from '../mini-slide/MiniSlide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import ComingSoonItem from '../coming-soon-item/ComingSoonItem';
import HomeBanners from '../home-banners/HomeBanners';
class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      products: [],
      best_seller_products: [],
      new_arrival_products: [],
      web_offer_products: [],
      sliderItems: [],
      featuredItems: [],
      personal_offer_products: [],
      personal_offer_campain: null,
      coming_soon: [],
      banners: []
    };
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    
    document.title = i18n.t('home_page_title');

    request.get(`api/slider-items`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        sliderItems: response.data,
      });
    });

    request.get(`api/featured-items`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        featuredItems: response.data,
      });
    });

    request.get(`api/categories`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      let data = response.data;
      let dataLength = data.length;

      
      this.setState({
        ...this.state,
        categories: data.slice(0, dataLength).map((category) => {
          return {
            id: category.id,
            name: category.name,
            name_en: category.name_en,
            image: category.image,
            background_image: category.third_image,
            productCount: category.productsCount,
            fallback_image: category.fallback_image,
          };
        }),
      });
    });

    request.get(`api/banners`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      let data = response.data;

      this.setState({
        banners: data
      });
    });

    request.get(`api/products/featured`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        products: response.data?.data?.products?.map((product) => {
          return {
            id: product.id,
            code: product.code,
            name: product.title,
            name_en: product.title_en,
            description: product.description,
            image: product.thumbnail || product.images[0],
            rating: product.rating,
            price: product.price,
            prices: product.prices,
            availability: product.availability,
            variants: product.variants,
            colors: product.colors,
            manufacturer: product.manufacturer,
            package_qty: product.package_qty,
            vat_percent: product.vat_percent,
            discount: product.discount ? product.discount : null,
            last_orders: product.last_orders ? product.last_orders : null
          };
        }),
      });
    });

    request.get(`api/coming_soon_products`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        coming_soon: response.data.articles
      });
    });

    request.get(`api/products/best_seller`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        best_seller_products: response?.data?.data?.products?.map((product) => {
          return {
            id: product.id,
            code: product.code,
            name: product.title,
            name_en: product.title_en,
            description: product.description,
            image: product.thumbnail || product.images[0],
            rating: product.rating,
            price: product.price,
            prices: product.prices,
            availability: product.availability,
            variants: product.variants,
            colors: product.colors,
            manufacturer: product.manufacturer,
            package_qty: product.package_qty,
            vat_percent: product.vat_percent,
            discount: product.discount ? product.discount : null,
            last_orders: product.last_orders ? product.last_orders : null
          };
        }),
      });
    });

    request.get(`api/products/web_offers`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        web_offer_products: response.data.data.products.map((product) => {
          return {
            id: product.id,
            code: product.code,
            name: product.title,
            name_en: product.title_en,
            description: product.description,
            image: product.thumbnail || product.images[0],
            rating: product.rating,
            price: product.price,
            prices: product.prices,
            availability: product.availability,
            variants: product.variants,
            colors: product.colors,
            manufacturer: product.manufacturer,
            discount: product.discount ? product.discount : null,
            last_orders: product.last_orders ? product.last_orders : null
          };
        }),
      });
    });

    request.get(`api/products/personal_offers`).then((response) => {
      if(!request.response_valid(response) || !response.data.data.products){
        return;
      }
      const p = response.data.data.products.map((product) => {
        return {
          id: product.id,
          code: product.code,
          name: product.title,
          name_en: product.title_en,
          description: product.description,
          image: product.thumbnail || product.images[0],
          rating: product.rating,
          price: product.price,
          prices: product.prices,
          availability: product.availability,
          variants: product.variants,
          colors: product.colors,
          manufacturer: product.manufacturer,
          discount: product.discount ? product.discount : null,
          last_orders: product.last_orders ? product.last_orders : null
        };
      })
      const c = response.data.data.campain != [] ? response.data.data.campain : null
      this.setState({
        ...this.state,
        personal_offer_products: p,
        personal_offer_campain: c
      });
    });

    request.get(`api/products/new_arrivals`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        new_arrival_products: response.data.data.products.map((product) => {
          return {
            id: product.id,
            code: product.code,
            name: product.title,
            name_en: product.title_en,
            description: product.description,
            image: product.thumbnail || product.images[0],
            rating: product.rating,
            price: product.price,
            prices: product.prices,
            availability: product.availability,
            variants: product.variants,
            colors: product.colors,
            manufacturer: product.manufacturer,
            discount: product.discount ? product.discount : null,
            last_orders: product.last_orders ? product.last_orders : null
          };
        }),
      });
    });

    request.get(`api/home_banners`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.setState({
        ...this.state,
        home_services: response.data
      });
    });
  
  }

  

  componentDidUpdate() {
    document.title = i18n.t('home_page_title');
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  onAddToBasket(product) {
    basket.addProduct(product, 1);
  }

  productSlice = (products = []) => {
    var half_f = []
    var inner = []
    var half_s = []
    var all = []
    if (!products || !Array.isArray(products) || products.length === 0 ) return {half_f,inner, half_s, all};

    if (products.length > 5) {
      const half = Math.floor(products.length/2);

      half_f = products.slice(0, half-1);
      inner = [products[half]]
      half_s = products.slice(half+1)
    }
    else {
      all = products
    }

    return {half_f, inner, half_s,all}
    
  }
  checkViewProduct = () => {
    if (this.state.products.length  > 2) {
      return 'multiple'
    }
  }

  render() {
    const banners_width = this.state.banners.length ? 100 / this.state.banners.length : 0

    const {half_f, inner, half_s,all} = {...this.productSlice(this.state.products)}
    //console.log(half_f, inner, half_s)

    return (
      <div className="home-page">
        {
          this.state.sliderItems.length
            ? <Slider {...{
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                adaptiveHeight: true
              }}>
                {
                  this.state.sliderItems.map((sliderItem, i) => {
                    if(sliderItem.action_url !== '' && i18n.get_generic(sliderItem, 'action_text') === ''){
                      return (
                      
                        <div>
                          <a
                            href={sliderItem.action_url}
                            target={sliderItem.action_url.includes(request.baseURL) ? '_self' : '_blank'}
                            key={i}
                            className="item d-flex flex-direction-row justify-content-center align-items-center slider-elem"
                            style={{backgroundImage: `url('${config.enviroment == 'production' ? config.imagesBaseURL : config.imagesDevBaseURL}slider/${sliderItem.image}?v=4')`, textDecoration: 'none'}} rel="noreferrer"
                          >
                            <div className="text m-5">
                              <div className="smallHeader fs-sm color-theme-text">{i18n.get_generic(sliderItem, 'small_header')}</div>
                              <div className="bigHeader fs-xxl color-theme-text">{i18n.get_generic(sliderItem, 'big_header')}</div>
                              <div className="mt-5">
                                {
                                  i18n.get_generic(sliderItem, 'action_text') != '' &&
                                  <a href={`${sliderItem.action_url}`} className="btn btn-secondary btn-outline fs-lg">{i18n.get_generic(sliderItem, 'action_text')}</a>
                                }
                                
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    }else{
                      return (
                      
                        <div>
                          {/* <div
                            key={i}
                            className="item d-flex flex-direction-row justify-content-center align-items-center slider-elem"
                            style={{backgroundImage: `url('${config.enviroment == 'production' ? config.imagesBaseURL : config.imagesDevBaseURL}slider/${sliderItem.image}?v=4')`}}
                          >
                            <div className="text m-5">
                            <div className="smallHeader fs-sm color-theme-text">{i18n.get_generic(sliderItem, 'small_header')}</div>
                              <div className="bigHeader fs-xxl color-theme-text">{i18n.get_generic(sliderItem, 'big_header')}</div>
                              <div className="mt-5">
                                {
                                  i18n.get_generic(sliderItem, 'action_text') != '' &&
                                  <a 
                                  target={sliderItem.action_url.includes(request.baseURL) ? '_self' : '_blank'}
                                  href={`${sliderItem.action_url}`} className="btn btn-secondary btn-outline fs-lg">{i18n.get_generic(sliderItem, 'action_text')}</a>
                                }
                                
                              </div>
                            </div>
                          </div> */}
                          <div
                            key={i}
                            className="item d-flex flex-direction-row justify-content-center align-items-center slider-elem"
                            style={{backgroundImage: `url('${config.enviroment == 'production' ? config.imagesBaseURL : config.imagesDevBaseURL}slider/${sliderItem.image}?v=4')`, textDecoration: 'none'}} rel="noreferrer"
                          >
                            <div className="text m-5">
                            <div className="smallHeader fs-sm color-theme-text">{i18n.get_generic(sliderItem, 'small_header')}</div>
                              <div className="bigHeader fs-xxl color-theme-text">{i18n.get_generic(sliderItem, 'big_header')}</div>
                              <div className="mt-5">
                                {
                                  i18n.get_generic(sliderItem, 'action_text') != '' &&
                                  <a 
                                  target={sliderItem.action_url.includes(request.baseURL) ? '_self' : '_blank'}
                                  href={`${sliderItem.action_url}`} className="btn btn-secondary btn-outline fs-lg" rel="noreferrer">{i18n.get_generic(sliderItem, 'action_text')}</a>
                                }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    
                  })
                }
              </Slider>
            : ''
        }
        {/* {
          ((this.state.personal_offer_products && this.state.personal_offer_products.length > 0) || this.state.personal_offer_campain !== null) ?
          <div className="section featured-products column">
            <div className='title-wrapper'>
              <h3 className="title">{i18n.t('personal_offer_products_title')}</h3>
            </div>
            <Slider {...{
              arrows: false,
              autoplay: true,
              autoplaySpeed: 3000,
              adaptiveHeight: true,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      arrows: false,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: false
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
            }}>
            {
              this.state.personal_offer_products.length > 0 && 
                this.state.personal_offer_products.map((product, i) => {
                  return (
                    <ProductItem
                      key={i}
                      data={product}
                      onAddToBasket={() => this.onAddToBasket(product)}
                    />
                  );
                })
            }
            {
              this.state.personal_offer_campain &&
                  <MiniSlide
                    data={{
                      image: `https://manager.dealmarkets.eu/files/${encodeURIComponent(this.state.personal_offer_campain.thumbnail)}`,
                      title: i18n.get_generic(this.state.personal_offer_campain, 'caption_general'),
                      id: this.state.personal_offer_campain.id,
                      link: `campain/${this.state.personal_offer_campain.id}`
                    }}
                  />
            }
            </Slider>
        </div> : ''
        } */}
        {/* <div className="section featured-products column">
          <div className='title-wrapper'>
            <h3 className="title">{i18n.t('best_seller_products_title')}</h3>
          </div>
            <Slider {...{
              arrows: false,
              autoplay: true,
              autoplaySpeed: 3000,
              adaptiveHeight: true,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      arrows: false,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: false
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
            }}>
            {
              this.state.best_seller_products.map((product, i) => {
                return (
                  <ProductItem
                    key={i}
                    data={product}
                    onAddToBasket={() => this.onAddToBasket(product)}
                  />
                );
              })
            }
            {
              this.state.best_seller_products.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
            </Slider>
        </div> */}

        <section>
          <HomeCategories categories={this.state.categories}/>
        </section>

        <section>
        <HomeBanners banners={this.state.featuredItems}></HomeBanners>
        </section>

        {/* FEATURED PRODUCTS SECTION */}

        {/*
        <div className='featured-products py-4 container'>
            <div className='feautered-list row'>
              <div className='featured-left col-lg-4 col-md-12'>
                <div className='row'>
                {
                  
                  half_f.map((product, i) => {
                    return (
                      <div className='col-lg-6 col-md-6'>
                      <ProductItem
                        key={i}
                        data={product}
                        onAddToBasket={() => this.onAddToBasket(product)}
                      />
                      </div>
                      
                    );
                  })
                }
                </div>
              </div>
              <div className='featured-inner col-lg-4 col-md-12'>
                <h3 className="title text-center">{i18n.t('featured_products_title')}</h3>
                {
                  
                  inner.map((product, i) => {
                    return (
                      <ProductItem
                        key={i}
                        data={product}
                        onAddToBasket={() => this.onAddToBasket(product)}
                      >
                      </ProductItem>
                    );
                  })
                }
                
              </div>
              <div className='featured-right col-lg-4 col-md-12'>
                <div className='row'>
                  {
                    half_s.map((product, i) => {
                      return (
                        <div className='col-lg-6 col-md-6'>
                          <ProductItem
                            key={i}
                            data={product}
                            onAddToBasket={() => this.onAddToBasket(product)}
                          />
                          </div>
                      );
                    })
                  }
                </div>
              </div>
                  {
                    all.map((product, i) => {
                      return (
                        <div className='col-lg-3 col-md-6'>
                          <ProductItem
                            key={i}
                            data={product}
                            onAddToBasket={() => this.onAddToBasket(product)}
                          />
                          </div>
                      );
                    })
                  }
            
            {
              this.state.products.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
            </div>
        </div>
          */}

	    {/* FEATURED PRODUCTS SECTION */}
      <div className='featured-products py-4 container'>
        <div className='featured-list row'>
          {this.state.products.length > 0 ? (
            this.state.products.map((product, i) => (
              <div className='col-lg-3 col-md-6 col-sm-12' key={i}>
                <ProductItem
                  data={product}
                  onAddToBasket={() => this.onAddToBasket(product)}
                />
              </div>
            ))
          ) : (
            Array.from(Array(10)).map((_, i) => (
              <div className='col-lg-3 col-md-6 col-sm-12' key={i}>
                <SkeletonProductItem />
              </div>
            ))
          )}
        </div>
      </div>  

        
        
        
        {
          this.state.coming_soon && this.state.coming_soon.length > 0 &&
          <div className="section featured-products column">
          <div className='title-wrapper'>
            <h3 className="title">{i18n.t('coming_soon')}</h3>
          </div>
            <Slider {...{
              arrows: false,
              autoplay: true,
              autoplaySpeed: 3000,
              adaptiveHeight: true,
              slidesToShow: 5,
              slidesToScroll: 5,
              responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      arrows: false,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                      infinite: true,
                      dots: false
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      arrows: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]
            }}>
            {
              this.state.coming_soon.map((article, i) => {
                return (
                  <ComingSoonItem key={i} data={article} />
                );
              })
            }
            {
              this.state.best_seller_products.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }
            </Slider>
        </div>
        }
        

     
          
        {
          this.state.home_services &&
            <section className='banner-info'>
              <HomeServices services={this.state.home_services}/>
            </section>
        }

{(this.state.web_offer_products.length === 0) ? (
  <></>
) : (
<div className="section featured-products">
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='title-wrapper'>
                  <h3 className="title">Web Offers</h3>
                </div>
                <Slider {...{
                  arrows: false,
                  autoplay: false,
                  autoplaySpeed: 3000,
                  slidesToShow: 5,
                  slidesToScroll: 5,

                    responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            arrows: false,
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,
                            dots: false
                          }
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            arrows: false,
                            slidesToShow: 1,
                            slidesToScroll: 1
                          }
                        }
                      ]
                }}>
                  {
                    this.state.web_offer_products.map((product, i) => {
                      return (
                        <ProductItem
                          key={i}
                          data={product}
                          onAddToBasket={() => this.onAddToBasket(product)}
                        />
                      );
                    })
                  }
                  {
                    this.state.products.length === 0 && Array.from(Array(10)).map((n, i) => {
                      return (
                        <SkeletonProductItem key={i}></SkeletonProductItem>
                      );
                    })
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
)}

{(this.state.best_seller_products.length === 0) ? (
  <></>
) : (
        <div className="section featured-products bg-new">
          <div className='container'>
            <div className='row'>
            <div className='title-wrapper1'>
              <h3 className="title">{i18n.t('best_seller_products_title')}</h3>
                {/* <p>{i18n.t("discover_our_offers")}</p> */}
            </div>
              <div className="list bg-item justify-left">
                <Slider {...{
                  arrows: false,
                  autoplay: true,
                  autoplaySpeed: 3000,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  responsive: [
                      {
                        breakpoint: 1200,
                        settings: {
                          arrows: false,
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          infinite: true,
                          dots: false
                        }
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          arrows: false,
                          slidesToShow: 1,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 480,
                        settings: {
                          arrows: false,
                          slidesToShow: 1,
                          slidesToScroll: 1
                        }
                      }
                    ]
                }}>
                {
                    this.state.best_seller_products.map((product, i) => {
                      return (
                        <ProductItem
                          key={i}
                          data={product}
                          onAddToBasket={() => this.onAddToBasket(product)}
                        />
                      );
                    })
                  }
                  {
                    this.state.best_seller_products.length === 0 && Array.from(Array(10)).map((n, i) => {
                      return (
                        <SkeletonProductItem key={i}></SkeletonProductItem>
                      );
                    })
                  }
                  </Slider>
                  <div className='right-title'>
                  <h3> Σας ευχαριστούμε θερμά, για την εμπιστοσύνη που μας δείχνετε.</h3>
                  <p>Η DealMarkets αναπτύσεται συνεχώς με στόχο την καλύτερη δυνατή εξυπηρέτηση των πελατών μας προμηθεύοντας 
                    τους καθημερινά με πιστοποιημένα και ποιοτικά προϊόντα στις καλύτερες τιμές.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
)}

{(this.state.new_arrival_products.length === 0) ? (
  <></>
) : (
      <section>
        <div className='container new-arrivals py-4'>
          <div className='title-wrapper'>
            <h3 className="title text-center">{i18n.t('new_arrivals_products_title')}</h3>
          </div>
          <div className='row'>
            {
              this.state.new_arrival_products.slice(0,1).map((product, i) => {
                return (
                  <div className='col-lg-4 col-md-6 offset-lg-0 offset-md-3 first-product'>
                  <ProductItem
                    key={i}
                    data={product}
                    onAddToBasket={() => this.onAddToBasket(product)}
                  >
                  {/* <div className='see-product-details'>
                      <Link to='#'>{i18n.t('more')}</Link>
                  </div> */}
                  </ProductItem>
                  </div>
                );
              })
            }
            <div className='col-lg-8 col-md-12'>
              <div className='row'>
                {
                  this.state.new_arrival_products.slice(1).map((product, i) => {
                    return (
                      <div className='flex-item'>
                      <ProductItem
                        key={i}
                        data={product}
                        onAddToBasket={() => this.onAddToBasket(product)}
                      />
                      </div>
                    );
                  })
                }
              </div>
            </div>
            {
              this.state.new_arrival_products.length === 0 && Array.from(Array(10)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i}></SkeletonProductItem>
                );
              })
            }

          </div>
        </div>
      </section>
)}      
       
          
        {/*
          (this.state.banners && this.state.banners.length > 0) &&
          <div className="section featured-products column">
            <div className='title-wrapper'>
              <h3 className="title">Brands</h3>
            </div>
            <div className="list">
              {/* <div className="item brand-item">
                <img src="https://manager.dealmarkets.eu/images/COTTON.png" alt="cotton logo" style={{height: '100px', width: 'auto', objectFit: 'contain', textAlign: 'center'}} />
              </div> * /}
              {
                this.state.banners.map(b => {
                  return (
                    <div className="item brand-item" style={{width: `${banners_width}%`}}>
                      <img src={b.path} alt={b.alt} style={{height: `${b.height}px`, width: 'auto', objectFit: 'contain', textAlign: 'center'}} />
                    </div>
                  )
                })
              }
            </div>
          </div>
            */}
        
      </div>
    );
  }
}

export default HomePage;